import React, { Component } from "react";

import Service from "../sharedComponents/Service";
import moment from 'moment';
import {edit} from '../../../assets/icons/index';
import { connect } from "react-redux";
import QuoteHeader from "../sharedComponents/QouteHeader/index";
import { DRIVER_DETAILS } from "../../../constants/ActionTypes";

class personalInfoSummary extends Component {
  service = new Service();
  constructor() {
    super();
    this.state = {      
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      gender : "",
      address: {
        addressLine1: "",
        addressLine2: "",
        postalCode: "",
        city: "",
        state: "",
        country: "",
      },
      DLNumber: "",
      edit : true,
    };
  }

  componentDidMount() {
    const { driverdata} = this.props.driverdetails;
    if (driverdata) {
      this.setState({ ...driverdata });
     // console.log(this.props.driverdetails)
       this.setState({
        firstName: driverdata.firstName || " ",
        lastName: driverdata.lastName || " ",
        gender: driverdata.gender || " ",
        dateOfBirth: driverdata.dateOfBirth,        
        DLNumber: driverdata?.DLNumber || " ",
        address: {
          ...driverdata.address,          
        }
      }); 
    }
  }


  previousPage() {
    //this.props.history.push("driverdetails");
    this.props.history.goBack();
  }

  nextPage() {
    this.props.dispatch({
      type: DRIVER_DETAILS,
      payload: this.state,
    });
   this.props.history.push("coverage-personal-information");
  }

  manualInputPage(){
   /*  this.setState({edit : !this.state.edit});
    this.enableUserEdit(); */
    this.props.history.push("driverdetails");
  }

  /* Formate "Date of Birth" in DD/MM/YYY */
  trimDate(dob) {
    if (typeof dob === 'object') {
      let localState = moment(dob).format(
        "YYYY-MM-DDTHH:mm:ss[Z]"
      );
      localState = localState.slice(0, localState.search('T')) || ' ';
      //console.log(moment(localState).format('DD/MM/YYYY'));
      return moment(localState).format('DD/MM/YYYY');

    } else {
      if (dob) {
        let DOB = dob.slice(0, dob.search('T')) || ' ';
        return moment(DOB).format('DD/MM/YYYY');
      } else {
        return '';
      }
    }

  }

  enableUserEdit(){
  
    if(this.state.edit === true){
      return {backgroundColor: 'rgb(255 255 255 / 0%)', border: 'none'};
    } else {
      return {backgroundColor: '', border: 'none'}
    }
   
  }

  updateUserDate(event){
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    console.log(event.target);
  }
  updateAddress(event){
    let address = { ...this.state.address };
    address[event.target.name] = event.target.value;
    this.setState({ address });
  }

  render() {
    const { firstName, lastName, dateOfBirth, gender, address, DLNumber } = this.state;
    return (
      <div className="container px-4 py-4">
        <div className="">
          <QuoteHeader
            page={1}
            previousPage={() => {
              this.previousPage();
            }}
            display={true}
          />
        </div>

        <div className="row">
          <p className="pageHeading"> Your info </p>
        </div>

        <div className="row mt-4 mb-3">
          <p className="pageDetalsUK">Let’s make sure we got the right details</p>
        </div>

          <div className="row gx-0 mt-5">
          <div className="card p-3 fs-5" style={{ color: "black", background: "rgb(255 255 255 / 77%)" }}>

            <div className="row">
              <div className="row">
                <div className="col-10 cardetails-vehicle-details-heading">
                  First Name

                </div>
                <div className="col-2">
                  <img className="" style={{marginTop:'-12px',marginLeft: '24px'}} src={edit} alt="edit" 
                  onClick={() => this.manualInputPage()}></img>
                </div>
              </div>
                           
              <div className="cardetails-vehicle-details captilize-firstLetter mb-3">
              <input type="firstName"  name="firstName" value={firstName} readOnly={this.state.edit} style={this.enableUserEdit()} onChange= {(event) => this.updateUserDate(event)}/>
              </div>

              <div className="mt-4 cardetails-vehicle-details-heading mb-1">Last name</div>
              <div className="cardetails-vehicle-details captilize-firstLetter mb-3">
                <input type="lastName"  name="lastName" value={lastName} readOnly={this.state.edit} style={this.enableUserEdit()} onChange= {(event) => this.updateUserDate(event)}/>
               </div>
              
              <div className="mt-4 cardetails-vehicle-details-heading mb-1">Date of birth</div>
              <div className="cardetails-vehicle-details mb-3">
              <input type="dateOfBirth"  name="dateOfBirth"  placeholder="DD/MM/YYYY" value=  {dateOfBirth} readOnly={this.state.edit} style={this.enableUserEdit()} onChange= {(event) => this.updateUserDate(event)} />
              </div>
              
              <div className="mt-4 cardetails-vehicle-details-heading mb-1">Driver’s license number</div>
              <div className="cardetails-vehicle-details mb-3">
              <input type="DLNumber"  name="DLNumber" value=  {DLNumber} readOnly={this.state.edit} style={this.enableUserEdit()} onChange= {(event) => this.updateUserDate(event)} />
              </div>
              
              <div className="mt-4 cardetails-vehicle-details-heading mb-1">Address line 1</div>
              <div className="cardetails-vehicle-details mb-3">
              <input type="addressLine1"  name="addressLine1"  value= {address.addressLine1} readOnly={this.state.edit} style={this.enableUserEdit()} onChange= {(event) => this.updateAddress(event)} /> 
              </div>
              
              <div className="mt-3 cardetails-vehicle-details-heading mb-1">City</div>
              <div className="cardetails-vehicle-details mb-3">
              <input type="city"  name="city" value= {address.city} readOnly={this.state.edit} style={this.enableUserEdit()} onChange= {(event) => this.updateAddress(event)} /> 
              </div>
              
              <div className="mt-3 cardetails-vehicle-details-heading mb-1">State</div>
              <div className="cardetails-vehicle-details mb-3">
              <input type="country"  name="country" value= {address.state} readOnly={this.state.edit} style={this.enableUserEdit()} onChange= {(event) => this.updateAddress(event)} />
              </div>
              <div className="mt-3 cardetails-vehicle-details-heading mb-1">Postal code</div>
              <div className="cardetails-vehicle-details mb-3">
              <input type="postalCode"  name="postalCode" value= {address.postalCode} readOnly={this.state.edit} style={this.enableUserEdit()} onChange= {(event) => this.updateAddress(event)} />
              </div>

              <div className="mt-3 cardetails-vehicle-details-heading mb-1">Gender</div>
              <div className="cardetails-vehicle-details mb-3">
              <input type="gender"  name="gender" value= {gender} readOnly={this.state.edit} style={this.enableUserEdit()} onChange= {(event) => this.updateUserDate(event)}/>
              </div>
            </div>

          </div>
        </div> 

        <div className="row my-5 gx-0">
          <button
            className="btn nexusButton btn-dark"
            style={{ fontSize: "14px" }}
            type="submit"
            onClick={() => this.nextPage()}
          >
            Looks Good!
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { driverdetails } = state;
  return {
    driverdetails: driverdetails,
  };
};

export default connect(mapStateToProps)(personalInfoSummary);
