import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './module/LandingPage';
import USRouter from './module/US/USRouter';
import UKRouter from './module/UK/UKRouter';

const MainRouter = () => (
  <Router>
    <Switch>
      <Route exact path='/' component={LandingPage}/> 
      <Route path='/us' component={USRouter}/>
      <Route path='/uk' component={UKRouter}/>
    </Switch>
  </Router>
)

export default MainRouter;