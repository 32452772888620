import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Welcome from './component/Welcome';
import DLScan from './component/DLScan';
import uploadDocument from './component/uploadDocument';
import DriverDetails from './component/DriverDetails';
import personalInfoSummary from './component/personalInfoSummary';
import CoveragePersonalInformation from './component/CoveragePersonalInformation';
import CoverageSelection from './component/CoverageSelection';
import Payment from './component/Payment';
import PaymentSuccess from './component/PaymentSuccess';
import Onboarding from './component/Onboarding'; 

/* 

import CoveragePersonalInformation from './component/CoveragePersonalInformation';
import CoverageSelection from './component/CoverageSelection';
import Payment from './component/Payment';
import PaymentSuccess from './component/PaymentSuccess';
import Onboarding from './component/Onboarding'; 

*/

const USRouter = () => (
    <Switch>
      <Route path='/us/welcome' component={Welcome}/>
      <Route path='/us/dlscan' component={DLScan}/>
      <Route path='/us/uploaddoc' component={uploadDocument}/>
      <Route path='/us/driverdetails' component={DriverDetails}/>
      <Route path='/us/personalInfoSummary' component={personalInfoSummary}/>
      <Route path='/us/coverage-personal-information' component={CoveragePersonalInformation}/>
      <Route path='/us/coverage-selection' component={CoverageSelection}/>
      <Route path='/us/payment' component={Payment}/>
      <Route path='/us/onboarding' component={Onboarding}/>
      <Route path='/us/paymentsuccess' component={PaymentSuccess}/> 
      
      {/* 
      
      
      <Route path='/us/coverage-personal-information' component={CoveragePersonalInformation}/>
      <Route path='/us/coverage-selection' component={CoverageSelection}/>
      <Route path='/us/payment' component={Payment}/>
      <Route path='/us/onboarding' component={Onboarding}/>
      <Route path='/us/paymentsuccess' component={PaymentSuccess}/> 
      
      */}
    </Switch>
)

export default USRouter;