import React, { Component } from 'react';
import moment from 'moment';
import { NexusFormField, NexusInput } from '@nexus/react';
import {dateFormate } from 'dinank';
import Service from '../sharedComponents/Service';
import { connect } from 'react-redux';
import QuoteHeader from '../../US/sharedComponents/QouteHeader/index';
import { DRIVER_DETAILS } from '../../../constants/ActionTypes';

class DriverDetails extends Component {
  service = new Service();
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        city: '',
        state: '',
        country: '',
      },
      DLNumber: 'TE57VRN',
    };
  }

  componentDidMount() {
    const { driverdata } = this.props.driverdetails;
    if (driverdata) {
      this.setState({ ...driverdata });
      console.log(this.props.driverdetails);
      this.setState({
        firstName: driverdata.firstName || '',
        lastName: driverdata.lastName || '',
        dateOfBirth: dateFormate(driverdata.dateOfBirth),
        //moment(driverdata.dateOfBirth).format("dd-mm-yyyy") || "",
        DLNumber: driverdata?.DLNumber || 'MICRO753116SM9IJ 35',
        address: {
          ...driverdata.address,
          addressLine1: '122 Burns Crescent',
          postalCode: 'EH1 9GP',
          city: 'Edinburgh',
          state: 'England',
          country: 'United Kingdom',
        },
      });
    }
  }

  previousPage() {
    this.props.history.push('uploaddoc');
  }

  nextPage() {
    this.props.dispatch({
      type: DRIVER_DETAILS,
      payload: this.state,
    });
    console.log(this.state);
    this.props.history.push('completedetails');
  }

  onTextChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onAddressChange = (e) => {
    let address = { ...this.state.address };
    address[e.target.name] = e.target.value;
    this.setState({ address });
  };

  setDOBState = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  render() {
    const { firstName, lastName, dateOfBirth, address, DLNumber } = this.state;
    return (
      <div className='container px-4 py-4'>
        <div>
          <QuoteHeader
            page={2}
            previousPage={() => {
              this.previousPage();
            }}
            display={true}
          />
        </div>

        <div className='row'>
          <p className='pageHeading'> DRIVER </p>
        </div>

        <div className='row mt-4 mb-3'>
          <p className='pageDetals'>Let’s look at your details!</p>
        </div>

        <div className='row gy-4' style={{}}>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3' style={{}}>
              First Name
            </label>
            <NexusInput
              type='firstName'
              placeholder='First Name'
              onChange={(event) => this.onTextChange(event)}
              name='firstName'
              value={firstName}
            />
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput2' className='form-label pageDetals mb-3' style={{}}>
              Last Name
            </label>
            <NexusInput
              placeholder='Last Name'
              onChange={(event) => this.onTextChange(event)}
              name='lastName'
              value={lastName}
            />
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput3' className='form-label pageDetals mb-3'>
              Date of Birth
            </label>
            <NexusInput
              data-testid='input-textbox-num'
              type='date'
              value={dateOfBirth}
              onInput={(event) => this.setDOBState(event.target.value)}
              min='100'
              max='1000'
              placeholder='Value...'
            ></NexusInput> 
            {/* <input
              type='date'
              className='form-control'
              id='exampleFormControlInput1'
              placeholder='Date of birth'
              onChange={(event) => this.setDOBState(event.target.value)}
              name='DOB'
              value={dateOfBirth.toString()}
              style={{
                height: '44px',
                backgroundColor: 'rgba(255, 255, 255, 0.77)',
              }}
            /> */}
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3'>
              Post Code
            </label>
            <NexusInput
              type='postCode'
              id='exampleFormControlInput1'
              placeholder='Post Code'
              onChange={(event) => this.onAddressChange(event)}
              name='postalCode'
              value={address.postalCode}
            />
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3'>
              Address
            </label>
            <NexusInput
              placeholder='Add Address here!'
              onChange={(event) => this.onAddressChange(event)}
              name='addressLine1'
              value={address.addressLine1}
            />
            {/* <textarea
              className="form-control"
              placeholder="Add Address here!"
              id="floatingTextarea"
              onChange={(event) => this.onAddressChange(event)}
              name="addressLine1"
              value={address.addressLine1}
              style={{ backgroundColor: "rgba(255, 255, 255, 0.77)" }}
            ></textarea> */}

            {/*  <textarea rows="5" cols="47" name="comment" form="usrform" defaultValue ="Address" style={{backgroundColor: 'rgba(255, 255, 255, 0.77)', paddingLeft : '10px'}}>
                    </textarea> */}
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3'>
              City
            </label>
            <NexusInput
              placeholder='City'
              onChange={(event) => this.onAddressChange(event)}
              name='city'
              value={address.city}
            />
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3'>
              Country
            </label>
            <NexusInput
              placeholder='Country'
              onChange={(event) => this.onAddressChange(event)}
              name='country'
              value={address.country}
            />
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3' style={{}}>
              Drivers License Number
            </label>
            <NexusInput
              placeholder='Drivers License Number'
              onChange={(event) => this.onTextChange(event)}
              name='DLNumber'
              value={DLNumber}
            />
          </div>
        </div>
        <div className='row my-5 gx-0'>
          <button
            className='btn nexusButton btn-dark'
            style={{ fontSize: '14px' }}
            type='submit'
            onClick={() => this.nextPage()}
          >
            Everything Looks Good!
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { driverdetails } = state;
  return {
    driverdetails: driverdetails,
  };
};

export default connect(mapStateToProps)(DriverDetails);
