export const COUNTRY_CODE = [
  {
    name: 'US',
    key: '1',
    value: '+1',
  },
  {
    name: 'UK',
    key: '44',
    value: '+44',
  },
];
