import React, { useState, useImperativeHandle, forwardRef } from "react";
import { NexusProgressBar, NexusProgressBarLabel } from '@nexus/react';
import useInterval from './useInterval';

const Loader = forwardRef((props, ref) => {

    const TEXT_START = 'Building Your Quote';
    const TEXT_END = 'Your Quote is Ready!';
    const [loading, setLoading] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useImperativeHandle(ref, () => ({
        loadingCompleted() {
            setIsLoading(false);
        }
    }));

    useInterval(() => {
        if (isLoading && loading < 75) {
            setLoading(loading + 1);
        } else if (!isLoading && loading < 100) {
            setLoading(loading + 1);
            if (loading === 99) {
                setTimeout(() => {
                    props.doneLoading();
                }, 1000)
            }
        }
    }, 100);

    return (
        <div className='loader-container'>
            <div className="col-xs-12 center">
                <NexusProgressBar
                    circle
                    value={loading}
                    showLabel={true}
                    labelComponent={
                        <span className='loader-percentage'>{loading}%</span>
                    }
                />
            </div>

            <div className="col-xs-12 center loading-text mt-10">{loading === 100 ? TEXT_END : TEXT_START}</div>
        </div>
    )
});

export default Loader;