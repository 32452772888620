import React, { useState, useEffect } from 'react';
import Service from '../sharedComponents/Service';
import { useHistory } from 'react-router-dom';
import '../style/Welcome.css';
import '../../../App.css';
import Insurance from '../../../assets/images/insurance.png';
import { search } from '../../../assets/icons';
import { useDispatch } from 'react-redux';
import { GET_TOKEN_SUCCESS, DRIVER_DETAILS } from '../../../constants/ActionTypes';
import QuoteHeader from '../sharedComponents/QouteHeader';

const Welcome = () => {
  const service = new Service();
  const [token, setToken] = useState('');
  const [DLNumber, setDLNumber] = useState('');
  const [error, setError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    tokenAPI();
  }, []);

  const tokenAPI = () => {
    service
      .getAuthToken()
      .then((res) => res.text())
      .then((data) => {
        dispatch({
          type: GET_TOKEN_SUCCESS,
          payload: data,
        });
        localStorage.setItem('tokenUS', data);
        /* console.log(token); */
        setToken(data);
      })
      .catch((err) => console.log(err));
  };
  const SearchParty = () => {
    service
      .serachParty(DLNumber)
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          const searchPartyRes = data.data.searchPartyResult[0];
          const { firstName, lastName, address, gender, nationality, dateOfBirth, driverLicenseNumber } = {
            ...searchPartyRes,
            ...searchPartyRes.partyContactDetails[0],
          };

          /*    setDriverDetails(
                    {firstName,
                    lastName,
                    address,
                    gender,
                    nationality,
                    dateOfBirth,
                    driverLicenseNumber}
                 ); */
          console.log(firstName, lastName, address, gender, nationality, dateOfBirth, driverLicenseNumber);
          dispatch({
            type: DRIVER_DETAILS,
            payload: { firstName, lastName, address, gender, nationality, dateOfBirth, DLNumber: driverLicenseNumber },
          });
          completeAddressPage();
          // history.push('driverdetails')
        }
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      });
  };

  const NavigateToDLScanPage = () => {
    history.push('dlscan');
  };

  const previousPage = () => {
    history.push('/carbon-offset');
  };
  const completeAddressPage = () => {
    history.push('personalInfoSummary');
  };

  return (
    <div className='container px-4 py-4'>
      <div className=''>
        <QuoteHeader
          page={1}
          previousPage={() => {
            previousPage();
          }}
          display={true}
        />
      </div>

      <div className='m-4'>
        <div className='row'>
          <div className='mb-5 text-center' style={{ fontSize: '24px', lineHeight: '20px' }}>
            Get a quote. Get it fast.
          </div>

          <div className='mb-4 text-center'>
            <img src={Insurance} className='welcome-image' alt='welcome'></img>
          </div>

          <div className='mb-5 text-center'>
            <button className='btn btn-dark nexusButton' onClick={() => NavigateToDLScanPage()} type='submit'>
              Start my quote
            </button>
          </div>

          <div className='mb-4'>
            <p className='pageDetals'>
              {' '}
              Already started a quote? Enter your Driver’s License number and we’ll fetch your details.
            </p>
          </div>

          <div className='mb-4 search-button-container mt-4'>
            <input
              id='name'
              onChange={(event) => {
                setDLNumber(event.target.value);
                setError(false);
              }}
              className='text-box'
              type='text'
              placeholder='Driver’s license number'
              required
            />
            <img src={search} onClick={() => SearchParty()} className='search-image' alt='search'></img>
          </div>

          {error && (
            <p className='alert alert-danger'>
              Sorry, we could not find your information. Please continue with a new quote
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Welcome;
