import React, { Component } from 'react';

// import '../style/DlScan.css';
import '../style/Welcome.css';

import Scan from '../../../assets/icons/Scan.png';
import { connect } from 'react-redux';
import QuoteHeader from '../../US/sharedComponents/QouteHeader/index';

class DLScan extends Component {
  constructor() {
    super();
    this.state = {};
  }

  previousPage() {
    this.props.history.push('cardetails');
  }
  nextPage() {
    this.props.history.push('uploaddoc');
  }
  enterInfoManually() {
    /*  this.props.history.push('uploaddoc'); */
  }
  render() {
    return (
      <div className='container px-4 py-4'>
        <div className=''>
          <QuoteHeader
            page={2}
            previousPage={() => {
              this.previousPage();
            }}
            display={true}
          />
        </div>

        <div className='row'>
          <p className='pageHeading'> DRIVER </p>
        </div>

        <div className='row mt-4'>
          <div className='mb-2'>
            <p className='pageDetals'> We would like to collect some basic information about you</p>
          </div>

          <div className='mb-4 text-center'>
            <img style={{ width: '90px', height: '65px' }} src={Scan} alt='scan' />
          </div>

          <div className='mb-5'>
            <p className='pageDetals'> Avoid manual entry by scanning your drivers license! </p>
          </div>
        </div>

        <div className='row gx-0'>
          <button type='button' className='btn btn-dark nexusButton' onClick={() => this.nextPage()}>
            Scan License
          </button>
        </div>

        <div className='row my-5 gx-0'>
          <button onClick={() => this.enterInfoManually()} className='btn btn-dark nexusButton'>
            Enter Information Manually
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    token: auth.data,
  };
};

export default connect(mapStateToProps)(DLScan);
