import React, { useState, useEffect } from 'react';
import Service from '../sharedComponents/Service';
import { useHistory } from 'react-router-dom';
import '../style/Welcome.css';
import '../../../App.css';
import flower from '../../../assets/images/UK_Flower.png';
import { useDispatch } from 'react-redux';
import { GET_TOKEN_SUCCESS } from '../../../constants/ActionTypes';
import QuoteHeader from '../../US/sharedComponents/QouteHeader/index';

const Welcome = () => {
  const service = new Service();
  const [token, setToken] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    tokenAPI();
  }, []);

  const tokenAPI = () => {
    service
      .getAuthToken()
      .then((res) => res.text())
      .then((data) => {
        dispatch({
          type: GET_TOKEN_SUCCESS,
          payload: data,
        });
        localStorage.setItem('token', data);
        console.log(token);
        setToken(data);
      })
      .catch((err) => console.log(err));
  };

  const NavigateToDLScanPage = () => {
    history.push('cardetails');
  };

  const previousPage = () => {
    history.push('/carbon-offset');
  };

  return (
    <div
      className='container py-4 welcome-screen'
      style={{ paddingRight: 'var(--bs-gutter-x,.100rem)', paddingLeft: 'var(--bs-gutter-x,.100rem)' }}
    >
      <div className='px-4'>
        <QuoteHeader
          page={1}
          previousPage={() => {
            previousPage();
          }}
          display={false}
        />
      </div>

      <div className='col-sm-4 col-sm-8 col-lg-12 m-4'>
        <div className='row' style={{ marginTop: '-50px' }}>
          <div className='mb-5 text-center' style={{ fontSize: '36px', fontWeight: 'bolder', lineHeight: '40px' }}>
            Create a Greener Future
          </div>

          <div className='mb-4'>
            <p className='pageDetals px-3' style={{ marginTop: '-20px' }}>
              {' '}
              Did you know that road transport makes up around 21% of the UK’s greenhouse gas emissions?
            </p>
          </div>
        </div>
      </div>

      <div className='row' style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
        <img src={flower} alt='flower'></img>

        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <p
            className='pageDetals px-5'
            style={{ margin: '46px 19px 40px 13px', fontSize: '20px', lineHeight: '18px' }}
          >
            {' '}
            Our insurance packages help you reduce your carbon emissions{' '}
          </p>
        </div>

        <div style={{ position: 'absolute', top: '85%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <button
            className='btn btn-dark nexusButton'
            style={{ width: '70%' }}
            onClick={() => NavigateToDLScanPage()}
            type='submit'
          >
            Start my quote
          </button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
