import { loadStripe } from '@stripe/stripe-js';
import jwtDecode from 'jwt-decode';
class Service {

  stripePromise = loadStripe(
    'pk_test_51IxQbgDQI89HrhjyM0OIxSb5no26gNSKfPqVGRKxwnDAq9QvKRWa7LkqpmhKHIzH1bKCUAMvR68cywLy781uzffy00yh3dkRwZ'
  );

  token = localStorage.getItem('token');
  
  API_URL = process.env.REACT_APP_BASE_API_URL;
  API_KEY = process.env.REACT_APP_BASE_API_KEY;
  API_ENV = process.env.REACT_APP_BASE_ENV;

  TOKEN_CLIENT_ID = process.env.REACT_APP_MISC_TOKEN_CLIENT_ID;
  TOKEN_CLIENT_SECRET = process.env.REACT_APP_MISC_TOKEN_CLIENT_SECRET;
  TOKEN_GRANT_TYPE = process.env.REACT_APP_MISC_TOKEN_GRANT_TYPE;
  TOKEN_RESOURCE = process.env.REACT_APP_MISC_TOKEN_RESOURCE;

  requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'access-control-allow-header': '*',
      // 'Authorization': this.checkTokenExpiry(),
      // `Bearer ${this.token}`,
      'apikey': this.API_KEY,
    },
    body: '',
  };

  tokenOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // apikey: 'xkMWwdz3wby200KWM7iCZsNk5mgoVC0z'
    },
    body: JSON.stringify({
      client_id: this.TOKEN_CLIENT_ID,
      client_secret: this.TOKEN_CLIENT_SECRET, 
      grant_type:  this.TOKEN_GRANT_TYPE,
      resource: this.TOKEN_RESOURCE,
    }),
  };

  checkTokenExpiry() {
    const decodedToken = jwtDecode(localStorage.getItem('token'));
  
    if (!decodedToken.exp > new Date((decodedToken.exp || 0) * 1000)) {
      return `Bearer ` + this.refreshToken();
    } else {
      return `Bearer ${this.token}`;
    }
  }

  refreshToken() {
    let refreshedToken = '';
    fetch( `https://nexusforinsurance-${this.API_ENV}.sbp.eyclienthub.com/ni/miscellaneous/app/keyvault/token`,
      this.tokenOptions
    )
      .then((response) => response.text())
      .then((token) => {
        localStorage.setItem('token', token);
        refreshedToken = token;
      });
    return refreshedToken;
  }
  /* AUTH TOKEN API  */
  getAuthToken() {
    console.log(process.env);
    return fetch(
      `https://nexusforinsurance-${this.API_ENV}.sbp.eyclienthub.com/ni/miscellaneous/app/keyvault/token`,
      this.tokenOptions
    );
  }

  getVehicleDetails(plateNo) {
    const body = JSON.stringify({
      registrationNumber: plateNo,
    });
    this.requestOptions.body = body;
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    return fetch(`${this.API_URL}/ni/vehicle/info/app/v1/get`, this.requestOptions);
  }

  updateTenant() {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}`, 'apikey': this.API_KEY },
      body: JSON.stringify({
        connectorType: 'vehicleInfo',
        insurTechCode: ['PL_001'],
        tenantId: 'Tenant-4100',
      }),
    };
    fetch(`${this.API_URL}/nexuscore/nc-tenant/demo/tenant`, requestOptions)
      .then((response) => response.json())
      .then((data) => console.log(data));
  }

  /* UPLOAD DOCUMENT CONNECTOR */

  documentscan(imageFrontSide, imageBackSide) {
    const body = JSON.stringify({
      imageFrontSide,
      imageBackSide,
    });

    this.requestOptions.body = body;
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    return fetch(`${this.API_URL}/ni/docscan/app/v1/sendcombinedimagedata`, this.requestOptions, { mode: 'no-cors' });
  }

  getcarbonemission() {
    const body = JSON.stringify({
      co2EmissionFromDvla: '3900',
      engineSize: '600',
      make: 'Fiat',
      model: '500',
      overallMileage: 89000,
      product: 'Auto',
      year: 2002,
      yearlyMileage: 800,
    });

    this.requestOptions.body = body;
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    return fetch(`${this.API_URL}/ni/carbonemission/app/v1/getcarbonemission`, this.requestOptions);
  }

  /* UPLOAD DOCUMENT CONNECTOR */

  serachParty(DLNumber) {
    const body = JSON.stringify({
      driverLicenseNumber: DLNumber,
    });
    this.requestOptions.body = body;
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    return fetch(`${this.API_URL}/ni/pcauto/party/demo/party/v1/searchparty`, this.requestOptions);
  }

  addressValidation(address) {
    this.requestOptions.headers['X-InsurCode'] = 'MOCK';
    const body = JSON.stringify({
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      country: address.country,
      stateProvince: address.state,
      zip: address.zip,
    });
    this.requestOptions.body = body;
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    return fetch(`${this.API_URL}/ni/intladdress/validation/app/v1/validateinternationaladdress`, this.requestOptions);
  }

  phoneNumberValidator(phoneNumber) {
    const body = JSON.stringify({
      phoneNumber,
    });
    this.requestOptions.body = body;
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    return fetch(`${this.API_URL}/ni/emailphone/app/v1/phonenumberverification`, this.requestOptions);
  }

  emailValidator(email) {
    const body = JSON.stringify({
      email,
    });
    this.requestOptions.body = body;
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    return fetch(`${this.API_URL}/ni/emailphone/app/v1/emailverification`, this.requestOptions);
  }

  coveragePersonalInformationSubmissionWF(reqBody) {
    const body = JSON.stringify({ ...reqBody });
    this.requestOptions.body = body;
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    return fetch(`${this.API_URL}/ni/pcauto/submission/wf/app/v1/createandacceptsubmission`, this.requestOptions);
  }

  issuePolicyAndGetCarbonVoucher(reqBody) {
    const body = JSON.stringify({ ...reqBody });
    this.requestOptions.body = body;
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    return fetch(`${this.API_URL}/pcauto/policy/wf/app/v1/issuepolicyandgetcarbonvoucher`, this.requestOptions);
  }

  /* Feedback Component */
  getFeedback() {
   /*  const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        'apikey': this.env.REACT_APP_BASE_API_KEY,
      },
      body: JSON.stringify({
        createdDate: '2021-07-02T00:00:00Z',
        system: 'policyAdmin',
      }),
    }; */
    this.requestOptions.body = JSON.stringify({
      createdDate: '2021-07-02T00:00:00Z',
      system: 'policyAdmin',
    });
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    // eslint-disable-next-line no-unused-expressions
    return fetch(`${this.API_URL}/ni/customerfeedback/app/v1/get`, this.requestOptions);
  }

  createFeedback(userFeedback) {
   /*  const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        'apikey': this.env.REACT_APP_BASE_API_KEY,
      },
      body: JSON.stringify({
        comment: userFeedback.comment,
        customerRating: userFeedback.customerRating,
        system: userFeedback.system,
      }),
    }; */

    this.requestOptions.body = JSON.stringify({
      comment: userFeedback.comment,
      customerRating: userFeedback.customerRating,
      system: userFeedback.system,
    });
    this.requestOptions.headers['Authorization'] = this.checkTokenExpiry();
    // eslint-disable-next-line no-unused-expressions
    return fetch(`${this.API_URL}/ni/customerfeedback/app/v1/create`, requestOptions);
  }

  async handleClick() {
    return await fetch(`${this.API_URL}/ni/paymentcheckout/app/demo/checkout/v1/createsession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'apikey': this.env.REACT_APP_BASE_API_KEY,
        'Authorization': `Bearer ${this.token}`,
        /*   "X-TenantID": "Tenant-4100", */
        'X-InsurCode': 'STRIPEPAYMENTAPI',
        'X-Remote-Json-Str': JSON.stringify({
          secretKey:
            't6JFzpbA8aRQY0DfB6wP2MDhNsFfM1/NAzHFnkPRZxQFLRIARg+CEhAGV7NkiMYS4exuBaquuOW9+0VNAT5Sh7LePdChRS6sJe0gh2+zLQ8qA47YxBV6nrKFAr9f1NBgG2Hn3Z/2cFxJmv5J/HOKYf/sZHNhQpPK+lQpBxE4+79LDwIvkZB/EidLicEgjd5+28lRXSvNUzeYZSTRQ1DYZH27X85PVqSpzdD2KGBwoW8KxIoXp/f4N9ZVhrAIKnuTXjqr9bfcBzH2wB2PmdoHQ9h3sANCwtrDyTj2yWbV8PaJPkp2l+NJh/dKt6ma75jmUWPiR38ou75RqcbG9knkbg==',
        }),
      },
      body: JSON.stringify({
        allowPromotionCodes: true,
        billingAddressCollection: 'required',
        cancelUrl: 'https://example.com/cancel',
        clientReferenceId: 'ref2568u89765',
        customerEmail: 'anil.kumar@ey.com',
        customerId: 'cus_H5ggYwtDq4fbrJ',
        discounts: [
          {
            coupon: 'COUPONFLAT40',
            promotionCode: 'FESTIVAL',
          },
        ],
        lineItems: [
          {
            dynamicTaxRates: true,
            priceId: 'price_1IxQeODQI89HrhjygYpHivpR',
            quantity: 1,
            taxRates: '5.9',
          },
        ],
        locale: 'en',
        metadata: {
          exampleMetadata: 'payment metadata',
        },
        mode: 'payment',
        paymentMethodOptions: {
          accsDebit: {
            currency: 'usd',
            mandateOptions: {
              customMandateURL: 'custom mandate url',
              intervalDescription: 'quarterly payment',
              paymentSchedule: 'interval',
            },
            verificationMethod: 'automatic',
          },
        },
        paymentMethodTypes: ['bacsdebit'],
        submitType: 'auto',
        successUrl: 'http://localhost:3000/#/uk/paymentsuccess',
        // successUrl: 'https://nexusforinsurance-autopolicy.azurewebsites.net/#/uk/paymentsuccess',
      }),
    });
  }
}

export default Service;
