class Service {
  token = localStorage.getItem('tokenUS');

  API_URL = process.env.REACT_APP_BASE_API_URL;
  API_KEY = process.env.REACT_APP_BASE_API_KEY;
  API_ENV = process.env.REACT_APP_BASE_ENV;

  TOKEN_CLIENT_ID = process.env.REACT_APP_MISC_TOKEN_CLIENT_ID;
  TOKEN_CLIENT_SECRET = process.env.REACT_APP_MISC_TOKEN_CLIENT_SECRET;
  TOKEN_GRANT_TYPE = process.env.REACT_APP_MISC_TOKEN_GRANT_TYPE;
  TOKEN_RESOURCE = process.env.REACT_APP_MISC_TOKEN_RESOURCE;

  requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'access-control-allow-header': '*',
      'Authorization': `Bearer ${this.token}`,
      'apikey': this.API_KEY,
    },
    body: '',
  };

  /* AUTH TOKEN API  */
  getAuthToken() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // apikey: 'xkMWwdz3wby200KWM7iCZsNk5mgoVC0z'
      },
      body: JSON.stringify({
        client_id: this.TOKEN_CLIENT_ID,
        client_secret: this.TOKEN_CLIENT_SECRET,
        grant_type: this.TOKEN_GRANT_TYPE,
        resource: this.TOKEN_RESOURCE,
      }),
    };
    return fetch(`https://nexusforinsurance-${this.API_ENV}.sbp.eyclienthub.com/ni/miscellaneous/app/keyvault/token`, requestOptions);
  }

  getVehicleDetails(plateNo) {
    const body = JSON.stringify({
      registrationNumber: plateNo,
    });
    this.requestOptions.body = body;
    return fetch(`${this.API_URL}/ni/vehicle/info/app/v1/get`, this.requestOptions);
  }

  updateTenant() {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token}`, 'apikey': this.API_KEY },
      body: JSON.stringify({
        connectorType: 'vehicleInfo',
        insurTechCode: ['PL_001'],
        tenantId: 'Tenant-4100',
      }),
    };
    fetch(this.API_URL + '/nexuscore/nc-tenant/demo/tenant', requestOptions)
      .then((response) => response.json())
      .then((data) => console.log(data));
  }

  /* UPLOAD DOCUMENT CONNECTOR */

  documentscan(imageFrontSide, imageBackSide) {
    const body = JSON.stringify({
      imageFrontSide,
      imageBackSide,
    });

    this.requestOptions.body = body;

    return fetch(`${this.API_URL}/ni/docscan/app/v1/sendcombinedimagedata`, this.requestOptions, { mode: 'no-cors' });
  }

  getcarbonemission() {
    const body = JSON.stringify({
      co2EmissionFromDvla: '3900',
      engineSize: '600',
      make: 'Fiat',
      model: '500',
      overallMileage: 89000,
      product: 'Auto',
      year: 2002,
      yearlyMileage: 800,
    });

    this.requestOptions.body = body;

    return fetch(`${this.API_URL}/ni/carbonemission/app/v1/getcarbonemission`, this.requestOptions);
  }

  /* UPLOAD DOCUMENT CONNECTOR */

  serachParty(DLNumber) {
    const body = JSON.stringify({
      driverLicenseNumber: DLNumber,
    });
    this.requestOptions.body = body;
    return fetch(`${this.API_URL}/ni/pcauto/party/demo/party/v1/searchparty`, this.requestOptions);
  }

  addressValidation(address) {
    this.requestOptions.headers['X-InsurCode'] = 'MOCK';
    const body = JSON.stringify({
      addressLine1: address.addressLine1,
      // addressLine2: address.addressLine2 || '',
      city: address.city,
      stateProvince: address.state,
      zip: address.postalCode,
      // country: 'US' /* address['country'] */,
    });
    this.requestOptions.body = body;

    return fetch(`${this.API_URL}/ni/address/validation/app/v1/validateaddress`, this.requestOptions);
  }

  phoneNumberValidator(phoneNumber) {
    this.requestOptions.headers['X-InsurCode'] = 'PRECISELY';
    const body = JSON.stringify({
      phoneNumber,
    });
    this.requestOptions.body = body;
    return fetch(`${this.API_URL}/ni/emailphone/app/v1/phonenumberverification`, this.requestOptions);
  }

  emailValidator(email) {
    const body = JSON.stringify({
      email,
    });
    this.requestOptions.body = body;
    return fetch(`${this.API_URL}/ni/emailphone/app/v1/emailverification`, this.requestOptions);
  }

  coveragePersonalInformationSubmissionWF(reqBody) {
    const body = JSON.stringify({ ...reqBody });
    this.requestOptions.body = body;
    return fetch(`${this.API_URL}/ni/pcauto/submission/wf/app/v1/createandacceptsubmission`, this.requestOptions);
  }

  issuePolicyAndGetCarbonVoucher(reqBody) {
    const body = JSON.stringify({ ...reqBody });
    this.requestOptions.body = body;
    return fetch(`${this.API_URL}/ni/pcauto/policy/wf/app/v1/issuepolicyandgetcarbonvoucher`, this.requestOptions);
  }

  /* Feedback Component */
  getFeedback() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        'apikey': this.env.REACT_APP_BASE_API_KEY,
      },
      body: JSON.stringify({
        createdDate: '2021-07-02T00:00:00Z',
        system: 'policyAdmin',
      }),
    };

    // eslint-disable-next-line no-unused-expressions
    return fetch(`${this.API_URL}/ni/customerfeedback/app/v1/get`, requestOptions);
  }

  createFeedback(userFeedback) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        'apikey': this.env.REACT_APP_BASE_API_KEY,
      },
      body: JSON.stringify({
        comment: userFeedback.comment,
        customerRating: userFeedback.customerRating,
        system: userFeedback.system,
      }),
    };

    // eslint-disable-next-line no-unused-expressions
    return fetch(this.API_URL + '/ni/customerfeedback/app/v1/create', requestOptions);
  }
}

export default Service;
