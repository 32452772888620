import React, { Component } from 'react';
import Select from 'react-select';
import Service from '../sharedComponents/Service';
import '../style/Welcome.css';
import '../../../App.css';
import { carDetails } from '../../../assets/icons/index';
import { search, plus, minus } from '../../../assets/icons';
import { CAR_DETAILS, USER_CAR_PREFERENCE } from '../../../constants/ActionTypes';
import QuoteHeader from '../../US/sharedComponents/QouteHeader/index';
import { connect } from 'react-redux';
import { NexusRadio } from '@nexus/react';

class Caretails extends Component {
  service = new Service();

  months = [
    { value: 'January', label: 'January' },
    { value: 'February', label: 'February' },
    { value: 'March', label: 'March' },
    { value: 'April', label: 'April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' },
  ];
  years = [
    { value: '2000', label: '2000' },
    { value: '2001', label: '2001' },
    { value: '2002', label: '2002' },
    { value: '2003', label: '2003' },
    { value: '2004', label: '2004' },
    { value: '2005', label: '2005' },
    { value: '2006', label: '2006' },
    { value: '2007', label: '2007' },
    { value: '2008', label: '2008' },
    { value: '2009', label: '2009' },
    { value: '2009', label: '2010' },
    { value: '2009', label: '2011' },
    { value: '2009', label: '2012' },
    { value: '2009', label: '2013' },
    { value: '2009', label: '2014' },
    { value: '2009', label: '2015' },
    { value: '2009', label: '2016' },
    { value: '2020', label: '2017' },
    { value: '2020', label: '2018' },
    { value: '2020', label: '2019' },
    { value: '2020', label: '2020' },
  ];
  mileage = [
    { YearlyMileage: '5000', MilesAWeek: '80 - 100', MilesADay: '12 - 15' },
    { YearlyMileage: '6000', MilesAWeek: '100 - 120', MilesADay: '15 - 18' },
    { YearlyMileage: '7000', MilesAWeek: '120 - 145', MilesADay: '19 - 21' },
    { YearlyMileage: '8000', MilesAWeek: '145 - 160', MilesADay: '21 - 24' },
    { YearlyMileage: '9000', MilesAWeek: '160 - 180', MilesADay: '24 - 26' },
    { YearlyMileage: '10000', MilesAWeek: '180 - 200', MilesADay: '26 - 28' },
    { YearlyMileage: '11000', MilesAWeek: '200 - 220', MilesADay: '29 - 32' },
    { YearlyMileage: '12000', MilesAWeek: '220 - 240', MilesADay: '33 - 35' },
    { YearlyMileage: '13000', MilesAWeek: '240 - 260', MilesADay: '35 - 37' },
    { YearlyMileage: '14000', MilesAWeek: '260 - 280', MilesADay: '38 - 40' },
    { YearlyMileage: '15000', MilesAWeek: '280 - 300', MilesADay: '40 - 43' },
  ];
  constructor() {
    super();
    this.state = {
      DLNumber: '',
      vehicleDetails: {},
      hideCarDetails: false,
      showMonthCalender: false,
      showYearCalender: false,
      detailsByUser: {
        month: '',
        year: '',
        modifications: '',
        workUse: '',
        pleasure: '',
        oftenUsed: '',
        moreThan2Tickets: '',
        milesPerYear: 10000,
        milesPerWeek: '180 - 200',
        milesPerDay: '26 - 28',
      },
    };
  }
  componentDidMount() {
    this.service.updateTenant();
    // let randomNumber = Math.ceil(Math.random() * 10);
    // console.log("randomNumber", randomNumber);
    // if (randomNumber % 2 === 0) {
    //   let localState = this.state.detailsByUser;
    //   console.log(localState);
    //   localState["oftenUsed"] = "yes";
    //   this.setState({
    //     detailsByUser: localState,
    //   });
    // }
  }

  previousPage() {
    this.props.history.push('welcome');
  }
  nextPage() {
    this.props.dispatch({
      type: USER_CAR_PREFERENCE,
      payload: this.state.detailsByUser,
    });
    this.props.history.push('dlscan');
  }
  /* NOT IN USE */
  addYear() {
    this.years = [{ value: '2000', label: '2000' }];
    for (let step = 1; step < 21; step++) {
      console.log(this.years[0].value + step && this.years.label + step);
      //this.years.push(this.years[0].value + step);
    }
  }

  setMonth(month) {
    console.log(month);
    let detailsByUser = { ...this.state.detailsByUser };
    detailsByUser['month'] = month;
    this.setState({ detailsByUser });
    this.displayCalendar();
  }

  setYear(year) {
    let detailsByUser = { ...this.state.detailsByUser };
    detailsByUser['year'] = year;
    this.setState({ detailsByUser });
    this.displayYearCalendar();
  }
  modification = (e) => {
    let detailsByUser = { ...this.state.detailsByUser };
    detailsByUser[e.target.name] = e.target.value;
    this.setState({ detailsByUser });
  };

  /* Logic for hide card details card */
  searchAgain() {
    this.setState({ hideCarDetails: false });
    this.setState({ DLNumber: '' });
  }

  /* API call for Vehicle Info  */
  SearchParty() {
    console.log('SSSSSSSSSSSSSSSSSS');
    this.service
      .getVehicleDetails(this.state.DLNumber)
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          const resForVehicleInfo = data.data;
          resForVehicleInfo['DLNumber'] = this.state.DLNumber;

          this.setState({ vehicleDetails: resForVehicleInfo });

          this.props.dispatch({
            type: CAR_DETAILS,
            payload: resForVehicleInfo,
          });

          this.setState({ hideCarDetails: true });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  displayCalendar() {
    this.setState({ showMonthCalender: !this.state.showMonthCalender });
  }

  displayYearCalendar() {
    this.setState({ showYearCalender: !this.state.showYearCalender });
  }

  calculateMiles(newvalue) {
    let detailsByUser = { ...this.state.detailsByUser };
    let milesPerYear = detailsByUser.milesPerYear + newvalue;
    if (milesPerYear <= 15000 && milesPerYear >= 5000) {
      detailsByUser['milesPerYear'] = milesPerYear;
      this.mileage.forEach((data) => {
        if (+data.YearlyMileage === milesPerYear) {
          //console.log(+(data.YearlyMileage), milesPerYear)
          detailsByUser['milesPerWeek'] = data.MilesAWeek;
          detailsByUser['milesPerDay'] = data.MilesADay;
        }
      });

      this.setState({ detailsByUser });
    }
  }

  render() {
    /*  this.addYear(); */
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: 'rgb(255 255 255 / 77%)',
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          'backgroundColor': 'rgb(255 255 255 / 77%)',
          'color': 'black',
          'cursor': isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: '',
          },
        };
      },
    };
    return (
      <div className='container px-4 py-4'>
        <div className=''>
          <QuoteHeader
            page={1}
            previousPage={() => {
              this.previousPage();
            }}
            display={true}
          />
        </div>

        <div className='row'>
          <p className='pageHeading'> CAR </p>
        </div>

        <div className='row mt-4'>
          <p className='pageDetals'>What is your car’s license plate?</p>
        </div>

        <div className='row'>
          <div className='mb-4 search-button-container'>
            <input
              id='name'
              style={{ background: 'rgb(255 255 255 / 77%)' }}
              onChange={(event) => {
                this.setState({ DLNumber: event.target.value });
              }}
              value={this.state.DLNumber}
              className='text-box fs-5'
              type='text'
              placeholder='Enter License Plate'
              required
            />
            <img
              style={{ background: 'rgb(255 255 255 / 77%)' }}
              src={search}
              onClick={() => this.SearchParty()}
              className='search-image'
              alt='search'
            ></img>
          </div>
        </div>
        {/* CAR DETAILS CARD WILL APPER ON RESPONSE FROM SERACH*/}
        {this.state.hideCarDetails && (
          <div className='row gx-0'>
            <div className='card p-3 fs-5' style={{ color: 'black', background: 'rgb(255 255 255 / 77%)' }}>
              <div className='row'>
                <div className='col-8'>
                  <div className='mb-1 cardetails-vehicle-details-heading' style={{ color: '#747480' }}>
                    {this.state.DLNumber}
                  </div>
                  <div className='cardetails-vehicle-details' style={{ fontWeight: 'bold' }}>
                    {/*  {this.state.vehicleDetails['make']} */} Vauxhall CORSA EXCLUSIV AC
                  </div>
                </div>

                <div className='col-4'>
                  <div className='mt-3' style={{}}>
                    <img src={carDetails} alt='car details'></img>
                  </div>
                </div>
              </div>

              <div className='row mt-3 row-cols-3'>
                <div className='col cardetails-vehicle-details-heading' style={{ color: '#747480' }}>
                  Year
                </div>
                <div className='col cardetails-vehicle-details-heading' style={{ color: '#747480' }}>
                  {' '}
                  Fuel
                </div>
                <div className='col cardetails-vehicle-details-heading' style={{ color: '#747480' }}>
                  {' '}
                  Engine
                </div>

                <div className='col cardetails-vehicle-details' style={{ fontWeight: 'bold' }}>
                  {this.state.vehicleDetails['yearOfManufacture']}
                </div>

                <div className='col cardetails-vehicle-details captilize-firstLetter' style={{ fontWeight: 'bold' }}>
                  {this.state.vehicleDetails['fuelType']}
                </div>

                <div className='col'>
                  <span className='cardetails-vehicle-details'>{`${this.state.vehicleDetails['engineCapacity']}`}</span>
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>cc</span>
                </div>
              </div>

              <div className='row mt-4'>
                <div className='col'>
                  <button className='btn btn-dark fs-5' type='submit' onClick={() => this.searchAgain()}>
                    Search Again
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* CAR DETAILS CARD END*/}

        <div className='row mt-3'>
          <p className='pageDetals'>When did you purchase the car?</p>
        </div>

        <div className='row'>
          <div className='col'>
            <Select
              style={{ height: '44px' }}
              options={this.months}
              styles={colourStyles}
              placeholder={'Month'}
              onChange={(month) => this.setMonth(month.value)}
            />

            {/* <select className="form-select dropDownbutton"           
                            onChange={(month) => this.setMonth(month.target.value)}> 
                            <option defaultValue style ={{fontSize:'14px'}}>Month </option>                          
                            {this.months.map((month, i) =>
                                <option key={ `month`+i} style={{fontSize:'10px',}} value={month}>
                                    {month}
                                </option>
                            )} 
                        </select>   */}
          </div>

          <div className='col'>
            <Select
              style={{ height: '44px' }}
              options={this.years}
              styles={colourStyles}
              placeholder={'Year'}
              onChange={(year) => this.setYear(year.value)}
            />

            {/*  <select className="form-select dropDownbutton"                          
                            onChange={(year) => this.setYear(year.target.value)}>    
                            <option defaultValue style ={{fontSize:'14px'}}>Year </option>                        
                            {this.years.map((year, i) =>
                                <option key={`year`+i} style={{fontSize:'10px'}} value={year}>
                                    {year}
                                </option>
                            )}
                        </select>  */}
          </div>
        </div>

        <div className='row mt-4'>
          <p className='pageDetals'>Does the car have any modifications?</p>
          <>
            <NexusRadio
              name='modifications'
              id='radio-1'
              value='yes'
              className='col-3'
              checked={this.state.detailsByUser.modifications === 'yes'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    modifications: 'yes',
                  },
                })
              }
            >
              Yes
            </NexusRadio>
            <NexusRadio
              className='col-4'
              name='modifications'
              id='radio-2'
              value='no'
              checked={this.state.detailsByUser.modifications === 'no'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    modifications: 'no',
                  },
                })
              }
            >
              No
            </NexusRadio>
          </>

          {/* <div className="row gx-0"  onChange={ this.modification}>
                        <div className="col" style={{ paddingRight: '0px' }}>
                            <div class="radio" style={{ marginLeft: '14px' }}>
                                <input id="radio-1" name="radio" type="radio" value="yes"/>
                                <label for="radio-1" class="radio-label">Yes</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-check form-check-inline">
                            <div class="radio">
                                <input id="radio-2" name="radio" type="radio" value="no"/>
                                <label for="radio-2" class="radio-label">No</label>
                            </div>
                            </div>
                        </div>
                    </div> */}
        </div>

        <div className='row mt-4'>
          <p className='pageDetals'>Do you use this car for work?</p>
          <>
            <NexusRadio
              name='workUse'
              id='inlineRadio1'
              value='yes'
              className='col-3'
              checked={this.state.detailsByUser.workUse === 'yes'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    workUse: 'yes',
                  },
                })
              }
            >
              Yes
            </NexusRadio>
            <NexusRadio
              name='workUse'
              id='inlineRadio2'
              value='no'
              className='col-4'
              checked={this.state.detailsByUser.workUse === 'no'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: { ...this.state.detailsByUser, workUse: 'no' },
                })
              }
            >
              No
            </NexusRadio>
            <NexusRadio
              name='workUse'
              id='inlineRadio3'
              value='sometimes'
              className='col-4'
              checked={this.state.detailsByUser.workUse === 'sometimes'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    workUse: 'sometimes',
                  },
                })
              }
            >
              Sometimes
            </NexusRadio>
          </>

          {/* <div className="row gx-0" onChange={this.modification}>
                        <div className="col">
                            <div className="form-check form-check-inline" style={{ marginLeft: '14px' }}>
                                <input className="form-check-input radiobutton" type="radio" name="workUse" id="inlineRadio1" value="yes" />
                                <label className="form-check-label inputlabel" htmlFor="workUse" >Yes</label>
                            </div>
                        </div>
                        <div className="col" style={{ paddingRight: '0px' }}>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input radiobutton" type="radio" name="workUse" id="inlineRadio1" value="no" />
                                <label className="form-check-label inputlabel" htmlFor="workUse" >No</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input radiobutton" type="radio" name="workUse" id="inlineRadio1" value="sometimes" />
                                <label className="form-check-label inputlabel" htmlFor="workUse" >Sometimes</label>
                            </div>
                        </div>
                    </div> */}
        </div>

        <div className='row mt-4'>
          <p className='pageDetals'>Do you use this car for pleasure?</p>
          <>
            <NexusRadio
              name='pleasure'
              id='inlineRadio1'
              value='never'
              className='col-3'
              checked={this.state.detailsByUser.pleasure === 'never'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    pleasure: 'never',
                  },
                })
              }
            >
              Never
            </NexusRadio>
            <NexusRadio
              name='pleasure'
              id='inlineRadio2'
              value='every day'
              className='col-4'
              checked={this.state.detailsByUser.pleasure === 'every day'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    pleasure: 'every day',
                  },
                })
              }
            >
              Every Day
            </NexusRadio>
            <NexusRadio
              name='pleasure'
              id='inlineRadio3'
              value='sometimes'
              className='col-4'
              checked={this.state.detailsByUser.pleasure === 'sometimes'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    pleasure: 'sometimes',
                  },
                })
              }
            >
              Sometimes
            </NexusRadio>
          </>

          {/* <div className="row gx-0" onChange={this.modification}>
                        <div className="col" style={{ paddingRight: '0px' }}>
                            <div className="form-check form-check-inline" style={{ marginLeft: '14px' }}>
                                <input className="form-check-input radiobutton" type="radio" name="pleasure" id="inlineRadio1" value="yes" />
                                <label className="form-check-label inputlabel" htmlFor="pleasure" >Yes</label>
                            </div>
                        </div>
                        <div className="col" style={{ paddingRight: '0px' }}>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input radiobutton" type="radio" name="pleasure" id="pleasure" value="everyDay" />
                                <label className="form-check-label inputlabel" htmlFor="pleasure" >Every Day</label>
                            </div>
                        </div>
                        <div className="col" style={{ paddingLeft: '0px' }}>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input radiobutton" type="radio" name="pleasure" id="inlineRadio1" value="sometimes" />
                                <label className="form-check-label inputlabel" htmlFor="pleasure" >Sometimes</label>
                            </div>
                        </div>
                    </div> */}
        </div>
        <div className='row mt-4'>
          <p className='pageDetals'>How often will the car be used?</p>
          {/* <div className="row gx-0" onChange={this.modification}>
                        <div className="col" style={{ paddingRight: '0px' }}>
                            <div className="form-check form-check-inline" style={{ marginLeft: '14px' }}>
                                <input className="form-check-input radiobutton" type="radio" name="oftenUsed" id="inlineRadio1" value="yes" />
                                <label className="form-check-label inputlabel" htmlFor="oftenUsed" >Yes</label>
                            </div>
                        </div>
                        <div className="col" style={{ paddingRight: '0px' }}>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input radiobutton" type="radio" name="oftenUsed" id="inlineRadio1" value="everyday" />
                                <label className="form-check-label inputlabel" htmlFor="oftenUsed" >Every Day</label>
                            </div>
                        </div>
                        <div className="col" style={{ paddingLeft: '0px' }}>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input radiobutton" type="radio" name="oftenUsed" id="inlineRadio1" value="somedays" />
                                <label className="form-check-label inputlabel" htmlFor="oftenUsed" >Some Days</label>
                            </div>
                        </div>
                    </div> */}

          <>
            <NexusRadio
              name='oftenUsed'
              id='inlineRadio1'
              value='never'
              className='col-3'
              checked={this.state.detailsByUser.oftenUsed === 'never'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    oftenUsed: 'never',
                  },
                })
              }
            >
              Never
            </NexusRadio>
            <NexusRadio
              name='oftenUsed'
              id='inlineRadio2'
              value='everyday'
              className='col-4'
              checked={this.state.detailsByUser.oftenUsed === 'everyday'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    oftenUsed: 'everyday',
                  },
                })
              }
            >
              Every Day
            </NexusRadio>
            <NexusRadio
              name='oftenUsed'
              id='inlineRadio3'
              value='sometimes'
              className='col-4'
              checked={this.state.detailsByUser.oftenUsed === 'sometimes'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    oftenUsed: 'sometimes',
                  },
                })
              }
            >
              Sometimes
            </NexusRadio>
          </>
        </div>

        <div className='row mt-4'>
          <p className='pageDetals'>Have you had more than 2 tickets in the past year?</p>
          <>
            <NexusRadio
              name='moreThan2Tickets'
              id='inlineRadio1'
              value='yes'
              className='col-3'
              checked={this.state.detailsByUser.moreThan2Tickets === 'yes'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    moreThan2Tickets: 'yes',
                  },
                })
              }
            >
              Yes
            </NexusRadio>
            <NexusRadio
              name='moreThan2Tickets'
              id='inlineRadio2'
              value='no'
              className='col'
              checked={this.state.detailsByUser.moreThan2Tickets === 'no'}
              onChange={(event) =>
                this.setState({
                  detailsByUser: {
                    ...this.state.detailsByUser,
                    moreThan2Tickets: 'no',
                  },
                })
              }
            >
              No
            </NexusRadio>
          </>
          {/* <div className="row gx-0" onChange={this.modification}>
                        <div className="col" style={{ paddingRight: '0px' }}>
                            <div className="form-check form-check-inline" style={{ marginLeft: '14px' }}>
                                <input className="form-check-input radiobutton" type="radio" name="moreThan2Tickets" id="inlineRadio1" value="yes"
                                />
                                <label className="form-check-label inputlabel" htmlFor="moreThan2Tickets" >Yes</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input radiobutton" type="radio" name="moreThan2Tickets" id="inlineRadio1" value="no" />
                                <label className="form-check-label inputlabel" htmlFor="moreThan2Tickets" >No</label>
                            </div>
                        </div>
                    </div> */}
        </div>

        <div className='row mt-4'>
          <p className='pageDetals'>I expect to drive the car</p>
        </div>

        <div className='row'>
          <p className='mb-1 text-center' style={{ fontSize: '12px', fontStyle: 'italic' }}>
            up to
          </p>

          <div className='row gx-0 center' style={{ color: 'black', fontSize: '20px', fontWeight: 'bolder' }}>
            <div
              className='dot'
              style={{ marginRight: '15px', fontSize: '56px' }}
              onClick={() => this.calculateMiles(-1000)}
            >
              <img style={{ cursor: 'pointer' }} src={minus} alt='minus' />
            </div>

            {this.state.detailsByUser.milesPerYear.toLocaleString('en-GB')}

            <div
              className='dot'
              style={{ marginLeft: '15px', fontSize: '40px' }}
              onClick={() => this.calculateMiles(1000)}
            >
              <img style={{ cursor: 'pointer' }} src={plus} alt='plus' />
            </div>
          </div>
          <p className='mt-1 text-center' style={{ fontSize: '12px', fontStyle: 'italic' }}>
            miles a year
          </p>
        </div>

        <div className='row mt-4'>
          <div className='row gx-0'>
            <div className='col-3 menu-line'></div>
            <div
              className='col-4 text-center'
              style={{
                marginTop: '-12px',
                fontSize: '12px',
                fontStyle: 'italic',
              }}
            >
              That’s roughly
            </div>
            <div className='col-3 menu-line'></div>
          </div>
        </div>

        <div className='row mt-3 gx-0 row-cols-2 ml3 text-center'>
          <div className='col' style={{ fontSize: '12px', fontStyle: 'italic' }}>
            Miles a Week
          </div>
          <div className='col' style={{ fontSize: '12px', fontStyle: 'italic' }}>
            {' '}
            Miles a Day{' '}
          </div>
          <div className='col' style={{ fontSize: '16px', color: 'black', fontWeight: 'bolder' }}>
            {this.state.detailsByUser.milesPerWeek}
          </div>
          <div className='col' style={{ fontSize: '16px', color: 'black', fontWeight: 'bolder' }}>
            {this.state.detailsByUser.milesPerDay}
          </div>
        </div>

        <div className='row my-5 gx-0'>
          <button
            className='btn nexusButton btn-dark'
            style={{ fontSize: '14px' }}
            type='submit'
            onClick={() => this.nextPage()}
          >
            Everything Looks Good!
          </button>
        </div>
      </div>
    );
  }
}

export default connect()(Caretails);
