import React, { Component } from 'react';
import { NexusModal, NexusModalFooter, NexusModalBody, NexusFormField } from '@nexus/react';
/*  import moment from "moment";  */
import Service from '../sharedComponents/Service';
import { connect } from 'react-redux';
import QuoteHeader from '../../US/sharedComponents/QouteHeader/index';
import { DRIVER_DETAILS } from '../../../constants/ActionTypes';

class DriverDetails extends Component {
  service = new Service();
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        city: '',
        state: '',
        country: '',
      },
      DLNumber: '',
      isModalVisible: false,
      modalAddress: ' ',
    };
  }

  componentDidMount() {
    // const { driverdata } = this.props.driverdetails;
    /*  if (driverdata) {
      this.setState({ ...driverdata });
      console.log(this.props.driverdetails)
       this.setState({
        firstName: driverdata.firstName || " ",
        lastName: driverdata.lastName || " ",
        gender: driverdata.gender || " ",
        dateOfBirth: driverdata.dateOfBirth.slice(0,driverdata.dateOfBirth.search('T')),
         NOT IN USE =  moment(driverdata.dateOfBirth, moment.defaultFormat).toDate() || " ",
        DLNumber: driverdata?.DLNumber || " ",
        address: {
          ...driverdata.address,
           addressLine1: "122 Burns Crescent",
          postalCode: "EH1 9GP",
          city: "Edinburgh",
          state: "England",
          country: "United Kingdom",
        }
      }); 
    } */
  }

  previousPage() {
    this.props.history.push('dlscan');
  }

  nextPage() {
    this.props.dispatch({
      type: DRIVER_DETAILS,
      payload: this.state,
    });
    this.props.history.push('personalInfoSummary');
  }

  onTextChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onAddressChange = (e) => {
    let address = { ...this.state.address };
    address[e.target.name] = e.target.value;
    this.setState({ address });
  };

  setDOBState = (date) => {
    console.log(date);
    this.setState({
      dateOfBirth: date,
    });
  };

  addresValidation() {
    this.service
      .addressValidation(this.state.address)
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          const { addressLine1, addressLine2, city, stateProvince, zip, country } = { ...data.data };
          this.modalAddress(addressLine1, city, stateProvince, zip);
          this.setAddressFromArressValidation(addressLine1, addressLine2, city, stateProvince, zip, country);
          this.setState({ isModalVisible: !this.state.isModalVisible });
        }
        console.log(data);
      });
  }

  modalAddress(addressLine1, city, stateProvince, zip) {
    const modalAddress = addressLine1 + ' ' + city + ', ' + stateProvince + ' ' + zip;
    this.setState({ modalAddress });
  }

  setAddressFromArressValidation(addressLine1, addressLine2, city, stateProvince, zip, country) {
    const localAddress = { addressLine1, addressLine2, city, state: stateProvince, postalCode: zip, country };
    console.log(localAddress);
    this.setState({ address: localAddress });
  }

  setModalVisivility() {
    this.setState({ isModalVisible: !this.state.isModalVisible });
    setTimeout(() => {
      this.nextPage();
    }, 1000);
  }

  KeepthisPage() {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  }

  render() {
    const { firstName, lastName, dateOfBirth, address, DLNumber } = this.state;
    return (
      <div className='container px-4 py-4'>
        <div>
          <QuoteHeader
            page={1}
            previousPage={() => {
              this.previousPage();
            }}
            display={true}
          />
        </div>

        <div className='row'>
          <p className='pageHeading'> Your info </p>
        </div>

        <div className='row mt-4 mb-3'>
          <p className='pageDetalsUK'>Please enter your details below</p>
        </div>

        <div className='row gy-4' style={{}}>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3' style={{}}>
              First Name
            </label>
            <input
              type='firstName'
              className='form-control firstName'
              id='exampleFormControlInput1'
              placeholder='First Name'
              onChange={(event) => this.onTextChange(event)}
              name='firstName'
              value={firstName.toLowerCase()}
              style={{
                height: '44px',
                backgroundColor: 'rgba(255, 255, 255, 0.77)',
              }}
            />
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput2' className='form-label pageDetals mb-3' style={{}}>
              Last Name
            </label>
            <input
              type='lastName'
              className='form-control firstName'
              id='exampleFormControlInput2'
              placeholder='Last Name'
              onChange={(event) => this.onTextChange(event)}
              name='lastName'
              value={lastName.toLowerCase()}
              style={{
                height: '44px',
                backgroundColor: 'rgba(255, 255, 255, 0.77)',
              }}
            />
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput3' className='form-label pageDetals mb-3'>
              Date of Birth
            </label>
            <input
              type='date'
              className='form-control'
              id='exampleFormControlInput1'
              placeholder='Date of birth'
              onChange={(event) => this.setDOBState(event.target.value)}
              name='DOB'
              value={dateOfBirth}
              style={{
                height: '44px',
                backgroundColor: 'rgba(255, 255, 255, 0.77)',
              }}
            />
            {/* <DatePicker selected={dateOfBirth} onChange={(date) => this.setDOBState(date)} dateFormat='dd-MM-yyyy' /> */}
            {/*  <NexusFormField>
              <DatePicker
                value={dateOfBirth || new Date() }
                onChange={(date) => this.setDOBState(date)}
                showLeadingZeros={true}
                format="dd-MM-yyyy"
                yearPlaceholder="YYYY"
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                placeholder = "Date of Birth"
              />
            </NexusFormField>  */}
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3'>
              Address Line 1
            </label>
            <input
              className='form-control'
              id='exampleFormControlInput1'
              placeholder='Address Line 1'
              onChange={(event) => this.onAddressChange(event)}
              name='addressLine1'
              value={address.addressLine1}
              style={{
                height: '44px',
                backgroundColor: 'rgba(255, 255, 255, 0.77)',
              }}
            />
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3'>
              City
            </label>
            <input
              type='city'
              className='form-control'
              id='exampleFormControlInput1'
              placeholder='City'
              onChange={(event) => this.onAddressChange(event)}
              name='city'
              value={address.city}
              style={{
                height: '44px',
                backgroundColor: 'rgba(255, 255, 255, 0.77)',
              }}
            />
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3'>
              State
            </label>
            <input
              type='state'
              className='form-control'
              id='exampleFormControlInput1'
              placeholder='state'
              onChange={(event) => this.onAddressChange(event)}
              name='state'
              value={address.state}
              style={{
                height: '44px',
                backgroundColor: 'rgba(255, 255, 255, 0.77)',
              }}
            />
          </div>
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3'>
              Postal Code
            </label>
            <input
              type='postCode'
              className='form-control'
              id='exampleFormControlInput1'
              placeholder='Post Code'
              onChange={(event) => this.onAddressChange(event)}
              name='postalCode'
              value={address.postalCode}
              style={{
                height: '44px',
                backgroundColor: 'rgba(255, 255, 255, 0.77)',
              }}
            />
          </div>

          {/* <div className="">
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label pageDetals mb-3"
            >
              Country
            </label>
            <input
              type="country"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Country"
              onChange={(event) => this.onAddressChange(event)}
              name="country"
              value={address.country}
              style={{
                height: "44px",
                backgroundColor: "rgba(255, 255, 255, 0.77)",
              }}
            />
          </div> */}
          <div className=''>
            <label htmlFor='exampleFormControlInput1' className='form-label pageDetals mb-3' style={{}}>
              Drivers License Number
            </label>
            <input
              type='DLN'
              className='form-control'
              id='exampleFormControlInput1'
              placeholder='Drivers License Number'
              onChange={(event) => this.onTextChange(event)}
              name='DLNumber'
              value={DLNumber}
              style={{
                height: '44px',
                backgroundColor: 'rgba(255, 255, 255, 0.77)',
              }}
            />
          </div>
        </div>
        <div className='row my-5 gx-0'>
          <button
            className='btn nexusButton btn-dark'
            style={{ fontSize: '14px' }}
            type='submit'
            onClick={() => this.addresValidation()}
          >
            Everything Looks Good!
          </button>
        </div>

        {/* Model to confirm validated address */}

        <NexusModal show={this.state.isModalVisible} onClose={() => this.setModalVisivility()}>
          <NexusModalBody
            style={{ color: '#1A1A24', fontWeight: 'bold', textAlign: 'center', fontSize: '18px', lineHeight: '18px' }}
          >
            Is this the correct address?
          </NexusModalBody>
          <NexusModalBody style={{ color: '#1A1A24', textAlign: 'center' }} className='package-price'>
            {' '}
            {this.state.modalAddress || ' '}{' '}
          </NexusModalBody>
          <NexusModalFooter>
            <div className='row'>
              <div className='col-5'>
                <button
                  className='btn btn-dark'
                  style={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    width: '120px',
                    height: '47px',
                    left: '28px',
                    top: '152px',
                  }}
                  type='submit'
                  onClick={() => this.setModalVisivility()}
                >
                  Looks good!
                </button>
              </div>

              <div className='col-5 offset-1'>
                <button
                  className='btn'
                  style={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    width: '120px',
                    height: '47px',
                    left: '28px',
                    top: '152px',
                    border: '1px solid black',
                    fontWeight: 'bold',
                  }}
                  type='submit'
                  onClick={() => this.KeepthisPage()}
                >
                  Not my address
                </button>
              </div>
            </div>

            {/* <MotifButton size="small" type="button" onClick={() => this.setModalVisivility()}  style={{fontSize:'12px', lineHeight: '12px' }}>
              Looks good!
            </MotifButton>
            <MotifButton
              size="small"
              variant="secondary"
              type="button"
              onClick={() => this.KeepthisPage()}
              style={{fontSize:'12px', lineHeight: '12px' }}>
              Not my address
            </MotifButton> */}
          </NexusModalFooter>
        </NexusModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  /* const { driverdetails } = state; */
  return {
    //driverdetails: driverdetails,
  };
};

export default connect(mapStateToProps)(DriverDetails);
