import React from "react";
import './style.scss';
import { eyLogo, arrowBack } from '../../../../assets/icons'

const QuoteHeader = ({ page ,previousPage, display, showBackButton = true }) => {
    const pages = [{
        key:1,
        id: 1,       
        title: "Welcome"
    },
    {
        key:2,
        id: 2,
        title: "Welcome"
    },
    {
        key:3,
        id: 3,
        title: "Welcome"
    },
    {
        key:4,
        id: 4,
        title: "Welcome"
    }];

    return (
        <div className="qoute-header-container us-header">
             <div className='header-text'>
             {showBackButton && <img src={arrowBack} style={{cursor:'pointer'}} alt="back-arrow" onClick={() => previousPage ? previousPage():{}}
                ></img>}
            </div>
            <div className="header-right">
                <div className="qoute-header">
                    <img src={eyLogo} className="ey-logo" alt="welcome"
                    ></img>
                    <div className='header-text'>Nexus</div>
                </div>
                { display && <div className="menu-container" >
                    {
                        pages.map((item, index) => <div className="menu-item" key={`header`+ index}>
                            <div key={`header`+ index} className={`menu-circle ${page === item.id || page==="#" ? 'active-circle' : ''}`} >
                                {item.id}
                            </div>
                            {index !== pages.length - 1 && <div key={`header1`+ index} className='menu-line'>
                            </div>}
                        </div>)
                    }
                </div>}
                
            </div>
        </div>
    );
}


export default QuoteHeader;