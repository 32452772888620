import { Component } from 'react';
import { NexusCheckbox } from '@nexus/react';
import QuoteHeader from '../sharedComponents/QouteHeader';
import { checkmark } from '../../../assets/icons';
import { connect } from 'react-redux';

class PaymentSuccess extends Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      policyStartDate: localStorage.getItem('policStartDate') ? localStorage.getItem('policStartDate') : '',
    };
  }
  nextPage() {
    this.props.history.push('onboarding');
  }
  getDateRAnge(date) {
    let startDate = new Date(date);
    let formatedDate = startDate.getDate() + '-' + startDate.getMonth() + '-' + startDate.getFullYear();
    let formatedEndDate = startDate.getDate() + '-' + startDate.getMonth() + '-' + (startDate.getFullYear() + 1);
    return formatedDate + ' to ' + formatedEndDate;
  }
  render() {
    const { policyNumber, policyEffectiveDate, grossFees } = this.props.issuepolicy ? this.props.issuepolicy : '';
    return (
      <div className='container payment-success-screen'>
        <QuoteHeader
          showBackButton={false}
          page={'#'}
          previousPage={() => {
            this.previousPage();
          }}
          display={true}
        />
        <div className='p-2'>
          <div className='white-card p-3 mt-4'>
            <img src={checkmark} style={{ marginTop: '-12px', position: 'absolute', right: '60px' }} alt='logo'></img>
            <div className='row'>
              <div className='col'>
                <div className='col card-title'>
                  <p className='card-title'> Payment Successful</p>
                </div>
                <div className='card-content'>
                  <div className='top-card-sub-title'>Policy Number: {policyNumber}</div>
                  <div className='top-card-sub-title'>Effective: {this.getDateRAnge(this.state.policyStartDate)}</div>
                  {/* <div className='top-card-sub-title'>Effective: {policyEffectiveDate?.split('T')[0]}</div> */}
                  {/* <div className='top-card-sub-title'>Payment: £ {grossFees}/month</div> */}
                  <div className='top-card-sub-title'>
                    Payment: £ {'70.00'}
                    <label style={{ fontSize: '10px' }}>/month</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='green-card p-3 text-center'>
            <div className='card-sub-title'>Your VAUXHALL is estimated to produce</div>
            {this.props.carbonemission?.predictedAnnualCo2Emissions && (
              <p className='carbon-emission-value'>{this.props.carbonemission.predictedAnnualCo2Emissions}</p>
            )}

            {/* <p className='carbon-emission-value'>2.435 tCO2e
                        </p> */}

            {/* <div className='carbon-emission-value'>sdfsdf</div> */}
            <div className='card-sub-title'>annualy</div>
            <div className='card-sub-title mt-4'>
              <label> We’ve partnered with </label>
              <label style={{ fontWeight: 600, marginLeft: '3px' }}>AQ Green </label>
              <label style={{ marginLeft: '3px' }}>
                to enable you to offset your emissions and we have committed to cover your{' '}
              </label>
              <label style={{ fontWeight: 600, marginLeft: '3px' }}>first three months of carbon offsets</label>
            </div>
          </div>

          <p className='offset-sub-text'>It is time to offset your Carbon Emissions</p>

          <div className='mx-3' style={{ marginTop: '30px', marginBottom: '30px' }}>
            <NexusCheckbox
              id='checkbox-example-0'
              value='checkbox-example'
              name='checkbox-example'
              checked={this.state.checked}
              onChange={() => {
                this.setState({ checked: !this.state.checked });
              }}
            >
              By redeeming your credits you are agreeing to the terms & conditions.
            </NexusCheckbox>
          </div>

          <button
            variant='primary-alt'
            className='full-button'
            disabled={!this.state.checked}
            style={{
              height: '48px',
            }}
            onClick={() => {
              this.nextPage();
            }}
          >
            Redeem your Carbon Credits
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { carbonemission, IssuepolicyAndGetCarbonVoucherAndPayment } = state;
  return {
    carbonemission: carbonemission?.data,
    issuepolicy:
      IssuepolicyAndGetCarbonVoucherAndPayment?.IssuepolicyAndGetCarbonVoucherAndPaymentData?.issuePolicyResponse,
  };
};

export default connect(mapStateToProps)(PaymentSuccess);
