import {
    USER_CAR_PREFERENCE,
} from '../constants/ActionTypes'

const initialState = {
    userCarPreference: null,
}


const userCarPreference = (state = initialState, action) => {
    
    switch (action.type) {
        case USER_CAR_PREFERENCE:
            return  {
                ...state,
                userCarPreference: action.payload
            }
        default:
            return state
    }
}

export default userCarPreference;



