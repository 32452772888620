import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage  from "redux-persist/lib/storage";
import auth from "./auth";
import SubmissionWFCoveragePersonelInfo from "./SubmissionWFCoveragePersonelInfo";
import IssuepolicyAndGetCarbonVoucherAndPayment from "./IssuepolicyAndGetCarbonVoucherAndPayment";

import cardetails from "./cardetails";
import userCarPreference from './userCarPreference';
import carbonemission from "./carbonemission";
import driverdetails from "./driverdetails";

const persistConfig ={
  key : 'root',
  storage,
}

const rootReducer = combineReducers({
  /* UK */
  auth,
  cardetails,
  userCarPreference,
  carbonemission,
  driverdetails,
  SubmissionWFCoveragePersonelInfo,
  IssuepolicyAndGetCarbonVoucherAndPayment,
});

export default persistReducer(persistConfig, rootReducer);
