import { ISSUE_POLICY_AND_GET_CARBON_VOUCHER_AND_PAYMENT } from "../constants/ActionTypes";

const initialState = {
  IssuepolicyAndGetCarbonVoucherAndPaymentData: null,
};

const IssuepolicyAndGetCarbonVoucherAndPayment = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ISSUE_POLICY_AND_GET_CARBON_VOUCHER_AND_PAYMENT:
      return {
        ...state,
        IssuepolicyAndGetCarbonVoucherAndPaymentData: action.payload,
      };
    default:
      return state;
  }
};

export default IssuepolicyAndGetCarbonVoucherAndPayment;
