import React, { Component } from 'react';
import iphone from '../../../assets/images/iphone.png';
import onboard1 from '../../../assets/images/onborad1.png';
import onboard2 from '../../../assets/images/onborad2.png';
import onboard3 from '../../../assets/images/onborad3.png';
import goGreen from '../../../assets/images/goGreen.svg';
import { appstore, playtore } from '../../../assets/icons';
import { InputGroup, FormControl, Toast } from 'react-bootstrap';
import copyIcon from '../../../assets/icons/copy.png';
import { connect } from 'react-redux';

class Onboarding extends Component {
  constructor() {
    super();
    this.state = {
      showToaster: false,
      creditResources: [
        {
          id: 1,
          title: 'Forest Conservation, Parcoy, Peru  ',
          image: onboard1,
        },
        {
          id: 2,
          title: 'Wind power Los Santos, Costa Rica',
          image: onboard2,
        },
        {
          id: 3,
          title: 'Reforestation in Kikonda, Uganda',
          image: onboard3,
        },
      ],
    };
  }
  hideToast = () => {
    this.setState({
      showToaster: false,
    });
  };

  showToaster = () => {
    navigator.clipboard.writeText(this.props.voucher);
    this.setState({
      showToaster: true,
    });
  };

  render() {
    const { showToaster } = this.state;
    const { voucher } = this.props;
    return (
      <div className='container p-5'>
        <div className='row mb-2 mt-2'>
          <div className='col go-green-wrapper'>
            <img src={goGreen} alt='goGreen'></img>
          </div>
          <div className='col-5'>
            <img src={iphone} alt='logo'></img>
          </div>
        </div>
        <p className='mb-4 download-text'>Download the AQ Green App and enter your carbon code on sign up</p>
        <div className='col-sm-12 voucher-field'>
          <div className='toaster-position'>
            <Toast className='toast-style' onClose={() => this.hideToast()} show={showToaster} delay={3000} autohide>
              <Toast.Body>Copied to clipboard</Toast.Body>
            </Toast>
          </div>

          <InputGroup
            className='mb-2'
            style={{
              height: '44px',
            }}
          >
            <FormControl id='inlineFormInputGroup' placeholder='Voucher Code' value={voucher} disabled />
            <InputGroup.Text>
              <img className='copy-icon' src={copyIcon} alt='copy' onClick={() => this.showToaster()} />
            </InputGroup.Text>
          </InputGroup>
        </div>
        <div className='row mt-5'>
          <p className='carbon-emission-text row center mb-1' style={{ marginLeft: '-3px' }}>
            and offset your remaining carbon emissions of
          </p>
          {this.props.carbonemission?.predictedAnnualCo2Emissions && (
            <div className='carbon-emission-code row center'>
              {this.props.carbonemission.predictedAnnualCo2Emissions}
            </div>
          )}
        </div>
        <div className='mb-5 mt-5'>
          <div className='row center'>
            <a rel='noreferrer' href='https://www.apple.com/in/macos/big-sur/' target='_blank' className='row center'>
              <img className='mb-2' src={appstore} alt='appstore' style={{ width: '186px', height: '61px' }}></img>
            </a>
          </div>
          <div className='row center'>
            <a
              rel='noreferrer'
              href='https://play.google.com/store?utm_source=apac_med&utm_medium=hasem&utm_content=Jul0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Jul0121-Text_Search_BKWS-BKWS%7cONSEM_kwid_43700058914955321_creativeid_480977427755_device_c&gclid=CjwKCAjwjdOIBhA_EiwAHz8xm7y7lPFD_o1T-5yn8_bpRt_ZSh14H-5F5yoPkcbXCCIaX5rDybzN0BoCblAQAvD_BwE&gclsrc=aw.ds'
              target='_blank'
              className='row center'
            >
              <img src={playtore} alt='playtore' style={{ width: '186px', height: '61px' }}></img>
            </a>
          </div>
        </div>
        <p className='row mb-5 resource-title'>Where your carbon credits come from</p>
        <div className='row'>
          {this.state.creditResources.map((item, index) => (
            <div key={index.toString()} className='mb-4'>
              <div>
                <img src={item.image} alt='onboard1'></img>
              </div>
              <p className='resource-card-text'>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { carbonemission, IssuepolicyAndGetCarbonVoucherAndPayment } = state;
  return {
    carbonemission: carbonemission?.data,
    voucher:
      IssuepolicyAndGetCarbonVoucherAndPayment?.IssuepolicyAndGetCarbonVoucherAndPaymentData?.voucherResponse?.voucher,
  };
};

export default connect(mapStateToProps)(Onboarding);
