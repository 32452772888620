import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuoteHeader from '../../US/sharedComponents/QouteHeader/index';
import GreenCheck from '../../../assets/icons/check-green.png';
import carbonNeutralPackage from '../../../assets/icons/carbon-neutral-package.png';
import carbonNeutralTelematics from '../../../assets/icons/carbon-neutral-telematics.png';
class CoverageSelection extends Component {
  constructor() {
    super();
    this.state = {
      coverageSelected: '',
      coverageAmount: '',
      loaded: false,
    };
  }

  previousPage() {
    this.props.history.push('coverage-personal-information');
  }

  componentDidMount() {}

  nextPage = () => {
    this.props.history.push('payment');
  };

  render() {
    return (
      <div className='container px-4 py-4'>
        <QuoteHeader
          page={3}
          previousPage={() => {
            this.previousPage();
          }}
          display={true}
        />

        <div className='row'>
          <div className='col-xs-12'>
            <p className='pageHeading' style={{ marginTop: '20px' }}>
              {' '}
              COVERAGE{' '}
            </p>
          </div>

          <div>
            <div className='col-xs-12 mt-3'>
              <p className='pageDetals'>Available packages to support your contributions to sustainability </p>
            </div>

            {/* <div className="col-xs-12 package-container mt-4"> */}
            <div
              className='col-xs-12 gx-0 card p-3 fs-5'
              style={{
                color: 'black',
                background: 'rgb(255 255 255 / 77%)',
                marginTop: '30px',
              }}
            >
              <div className='row'>
                <div className='col-10'>
                  <div className='row'>
                    <div className='col-xs-12 package-heading'>Carbon Neutral Package</div>
                    <div className='col-xs-12 inline-items mt-2'>
                      <img src={GreenCheck} alt='' className='green-check' />
                      <div className='ms-2 package-details'>3 months of Carbon Credits</div>
                    </div>
                    <div className='col-xs-12 inline-items mt-1'>
                      <img src={GreenCheck} alt='' className='green-check' />
                      <div className='ms-2 package-details'>1 driver, 1 car</div>
                    </div>
                    <div className='col-xs-12 inline-items mt-1'>
                      <img src={GreenCheck} alt='' className='green-check' />
                      <div className='ms-2 package-details'>12 months</div>
                    </div>
                    <div className='col-xs-12 inline-items mt-1'>
                      <img src={GreenCheck} alt='' className='green-check' />
                      <div className='ms-2 package-details'>Euro Drive Cover</div>
                    </div>
                  </div>
                </div>
                <div className='col-2'>
                  <img className='package-icon' src={carbonNeutralPackage} alt='' />
                </div>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-8' style={{ marginTop: '10px' }}>
                      <span className='package-price'>£ 70.00</span>
                      <span className='package-price-month'>/Month</span>
                    </div>
                    <div className='col-4'>
                      <button
                        className='btn package-selection-button btn-dark'
                        style={{ fontSize: '14px' }}
                        type='submit'
                        onClick={() => this.nextPage()}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-xs-12 package-container mt-5"> */}
            <div
              className='col-xs-12 gx-0 card p-3 fs-5 mt-4'
              style={{
                overflow: 'hidden',
                color: 'black',
                background: 'rgb(255 255 255 / 77%)',
              }}
            >
              <div className='row'>
                <div className='col-10'>
                  <div className='row'>
                    <div className='col-xs-12 package-heading'>Carbon Neutral Telematics</div>
                    <div className='col-xs-12 inline-items mt-2'>
                      <img src={GreenCheck} alt='' className='green-check' />
                      <div className='ms-2 package-details'>3 months of Carbon Credits</div>
                    </div>
                    <div className='col-xs-12 inline-items mt-1'>
                      <img src={GreenCheck} alt='' className='green-check' />
                      <div className='ms-2 package-details'>1 driver, 1 car</div>
                    </div>
                    <div className='col-xs-12 inline-items mt-1'>
                      <img src={GreenCheck} alt='' className='green-check' />
                      <div className='ms-2 package-details'>12 months</div>
                    </div>
                    <div className='col-xs-12 inline-items mt-1'>
                      <img src={GreenCheck} alt='' className='green-check' />
                      <div className='ms-2 package-details'>Euro Drive Cover</div>
                    </div>
                  </div>
                </div>
                <div className='col-2'>
                  <img className='package-icon' src={carbonNeutralTelematics} alt='' />
                </div>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-12' style={{ marginTop: '10px' }}>
                      <span className='package-price'>£ 62.00</span>
                      <span className='package-price-month'>/Month</span>
                    </div>
                    <div className='carbon-neutral-telematics-sticker'>Coming Soon</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(CoverageSelection);
