import React, { Component } from 'react';
import Service from '../sharedComponents/Service';
import moment from 'moment';
import { carDetails } from '../../../assets/icons/index';
import { connect } from 'react-redux';
import QuoteHeader from '../../US/sharedComponents/QouteHeader/index';
import { CARBON_EMISSION } from '../../../constants/ActionTypes';

class CarDriverDetails extends Component {
  service = new Service();
  constructor() {
    super();
    this.state = {
      cardetails: {},
      carbonemission: '',
      driverdetails: {},
      userCarPreference: {},
    };
  }

  componentDidMount() {
    const { cardetails, userCarPreference } = this.props;
    let driverdetails = this.props.driverdetails;

    console.log('driverdetails', driverdetails);
    // console.log('componentDidMount driverdetails', driverdetails);
    this.carbonEmmission();
    this.setState({
      cardetails: cardetails,
      userCarPreference: userCarPreference,
    });
    this.setState({
      driverdetails: driverdetails,
    });
  }

  // componentDidUpdate(prevProps , prevState){
  //   console.log(prevProps);
  //   console.log(this.props);
  // }

  /* API call for carbonEmmission */
  carbonEmmission() {
    this.service
      .getcarbonemission()
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          let carbonEmmission = data.data;
          this.setState({
            carbonemission: carbonEmmission.predictedAnnualCo2Emissions,
          });
          this.props.dispatch({
            type: CARBON_EMISSION,
            payload: carbonEmmission,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  previousPage() {
    this.props.history.push('driverdetails');
  }

  nextPage() {
    this.props.history.push('coverage-personal-information');
  }

  render() {
    const { cardetails, carbonemission, userCarPreference, driverdetails } = this.state;
    return (
      <div className='container px-4 py-4'>
        <div className=''>
          <QuoteHeader
            page={2}
            previousPage={() => {
              this.previousPage();
            }}
            display={true}
          />
        </div>

        <div className='row'>
          <p className='pageHeading'> CAR & DRIVER </p>
        </div>

        <div className='row mt-4 mb-3'>
          <p className='pageDetals'>Does the below look right?</p>
        </div>

        <div className='row gx-0'>
          <div className='card p-3 fs-5' style={{ color: 'black', background: 'rgb(255 255 255 / 77%)' }}>
            <div className='row'>
              <div className='col-8'>
                <div className='mb-1 cardetails-vehicle-details-heading'>{cardetails?.DLNumber}</div>
                <div className='cardetails-vehicle-details' style={{ fontWeight: 'bold' }}>
                  {/*  {this.state.vehicleDetails['make']} */} Vauxhall CORSA EXCLUSIV AC
                </div>
              </div>

              <div className='col-4'>
                <div className='mt-3' style={{}}>
                  <img src={carDetails} alt='car details'></img>
                </div>
              </div>
            </div>

            <div className='row mt-3 row-cols-3'>
              <div className='col cardetails-vehicle-details-heading'>Year</div>
              <div className='col cardetails-vehicle-details-heading'> Fuel</div>
              <div className='col cardetails-vehicle-details-heading'> Engine</div>

              <div className='col cardetails-vehicle-details' style={{ fontWeight: 'bold' }}>
                {cardetails.yearOfManufacture}
              </div>

              <div className='col cardetails-vehicle-details captilize-firstLetter' style={{ fontWeight: 'bold' }}>
                {cardetails.fuelType}
              </div>

              <div className='col cardetails-vehicle-details' style={{ fontWeight: 'bold' }}>
                <span className='cardetails-vehicle-details'>{cardetails.engineCapacity}</span>
                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>cc</span>
              </div>
            </div>

            <div className='row row-cols-1 mt-4'>
              <div className='col cardetails-vehicle-details-heading'>Estimated Yeary Mileage</div>
              <div className='col cardetails-vehicle-details' style={{ fontWeight: 'bold' }}>
                {parseFloat(userCarPreference.milesPerYear).toLocaleString('en')}
              </div>
            </div>
          </div>
        </div>

        <div className='row gx-0 mt-5'>
          <div className='card p-3 fs-5' style={{ color: 'black', background: 'rgb(255 255 255 / 77%)' }}>
            <div className='row text-center'>
              <div className='car-carbon-emission-text'>Your VAUXHALL is estimated to produce</div>
              <div className='carbon-emission-value'>{carbonemission}</div>
              <div className='car-carbon-emission-text'>annualy</div>
            </div>
          </div>
        </div>

        {driverdetails.address && (
          <div className='row gx-0 mt-5'>
            <div className='card p-3 fs-5' style={{ color: 'black', background: 'rgb(255 255 255 / 77%)' }}>
              <div className='row'>
                <div className='cardetails-vehicle-details-heading mb-2'>First name</div>
                <div className='cardetails-vehicle-details captilize-firstLetter'>{driverdetails.firstName}</div>

                <div className='mt-4 cardetails-vehicle-details-heading mb-2'>Last name</div>
                <div className='cardetails-vehicle-details captilize-firstLetter'>{driverdetails.lastName}</div>

                <div className='mt-4 cardetails-vehicle-details-heading mb-2'>Date of birth</div>
                <div className='cardetails-vehicle-details'>
                  {moment(driverdetails.dateOfBirth).format('DD-MM-YYYY')}
                </div>

                <div className='mt-4 cardetails-vehicle-details-heading mb-2'>Driver’s license number</div>
                <div className='cardetails-vehicle-details'>{driverdetails?.DLNumber}</div>

                <div className='mt-4 cardetails-vehicle-details-heading mb-2'>Address</div>
                <div className='cardetails-vehicle-details'>{driverdetails.address.addressLine1}</div>

                <div className='mt-3 cardetails-vehicle-details-heading'>City</div>
                <div className='cardetails-vehicle-details'>{driverdetails.address.city}</div>

                <div className='mt-3 cardetails-vehicle-details-heading'>Country</div>
                <div className='cardetails-vehicle-details'>{driverdetails.address.country}</div>
                <div className='mt-3 cardetails-vehicle-details-heading'>Postal code</div>
                <div className='cardetails-vehicle-details'>{driverdetails.address.postalCode}</div>
              </div>
            </div>
          </div>
        )}

        <div className='row my-5 gx-0'>
          <button
            className='btn nexusButton btn-dark'
            style={{ fontSize: '14px' }}
            type='submit'
            onClick={() => this.nextPage()}
          >
            Looks Correct!
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { cardetails, carbonemission, driverdetails, userCarPreference } = state;
  console.log('driverdetails', driverdetails.driverdata);
  return {
    cardetails: cardetails.data,
    carbonemission: carbonemission.data,
    userCarPreference: userCarPreference.userCarPreference,
    driverdetails: driverdetails.driverdata,
  };
};

export default connect(mapStateToProps)(CarDriverDetails);
