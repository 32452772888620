import React, { Component } from 'react';
import Service from '../sharedComponents/Service';
import { DRIVER_DETAILS } from '../../../constants/ActionTypes';

import { checkCircle } from '../../../assets/icons/index';
import Scan from '../../../assets/icons/Scan.png';
import { connect } from 'react-redux';
import QuoteHeader from '../../US/sharedComponents/QouteHeader/index';

class uploadDocument extends Component {
  service = new Service();
  constructor() {
    super();
    this.state = {
      hideFrontScan: false,
      hideBackScan: false,
      frontImagePreview: '',
      backImagePreview: '',
      imageFrontSide: '',
      imageBackSide: '',
      driverDataFromDocumentScan: {},
    };
  }
  previousPage() {
    this.props.history.push('dlscan');
  }
  nextPage() {
    this.props.history.push('driverdetails');
  }

  /* converting uploaded document into Base64 form */
  uploadDoc(event, side) {
    const fileUploaded = event.target.files[0];
    let imageType = event.target.files[0].type;
    const fr = new FileReader();
    fr.onloadend = () => {
      if (side === 'FrontSide') {
        this.setState({ hideFrontScan: true });
        this.setState({ frontImagePreview: fr.result });
        this.setState({
          imageFrontSide: fr.result.replace('data:' + imageType + ';base64,', ''),
        });
      } else {
        this.setState({ hideBackScan: true });
        this.setState({ backImagePreview: fr.result });
        this.setState({
          imageBackSide: fr.result.replace('data:' + imageType + ';base64,', ''),
        });
      }
    };
    fr.readAsDataURL(fileUploaded);
  }

  /* API call for Document Scan connector */
  continue() {
    try {
      this.service
        .documentscan(this.state.imageFrontSide, this.state.imageBackSide)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            let uploadedDocRes = data.data.result;
            const { firstName, lastName, address, gender, nationality, dateOfBirth } = {
              ...uploadedDocRes,
              ...uploadDocument.mrzData,
            };
            this.setState({
              driverDataFromDocumentScan: {
                firstName,
                lastName,
                address,
                gender,
                nationality,
                dateOfBirth,
              },
            });
            this.props.dispatch({
              type: DRIVER_DETAILS,
              payload: this.state.driverDataFromDocumentScan,
            });
            // console.log(this.state.driverDataFromDocumentScan);

            this.nextPage();
          } else {
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  clearScanData() {
    this.setState({ imageBackSide: '' });

    this.setState({ imageFrontSide: '' });
  }

  disableText(side) {
    let styleBefore = {
      color: ' #747480',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    let styleAfter = {
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    if (side === 'front') {
      if (this.state.hideFrontScan) {
        return styleBefore;
      } else {
        return styleAfter;
      }
    } else {
      if (this.state.hideBackScan) {
        return styleBefore;
      } else {
        return styleAfter;
      }
    }
  }

  render() {
    return (
      <div className='container px-4 py-4'>
        <div className=''>
          <QuoteHeader
            page={2}
            previousPage={() => {
              this.previousPage();
            }}
            display={true}
          />
        </div>

        <div className='row'>
          <p className='pageHeading'> DRIVER </p>
        </div>

        <div className='row mt-4 mb-2'>
          <p className='pageDetals'>Time to Scan your license</p>
        </div>

        <div className='row center gy-4 mx-4' style={{ fontSize: '12px', position: 'relative' }}>
          <div className='rounderDiv' style={{}}>
            <span className='roundDot'> </span>
            <div style={{ marginTop: '6px' }}>Open your camera</div>
          </div>

          {/* <div
            className=""
            style={{
              borderLeft: "2px solid black",
              height: "129px",
              position: "absolute",
              marginLeft: "38px",
              zIndex: '2'
            }}
          ></div> */}

          <div className='rounderDiv'>
            <span className='roundDot' style={{ position: 'absolute' }}>
              {' '}
            </span>
            <div style={{ marginTop: '6px' }}>Take pictures of your license</div>
          </div>

          <div className='rounderDiv'>
            <span className='roundDot'> </span>
            <div style={{ marginTop: '6px' }}>Upload your pictures</div>
          </div>
          {/*  <input type='text' value= 'Take pictures of your license' readOnly
                    style={{width: '80%',height: '36px', borderRadius : '20px', textAlign : 'center', backgroundColor: 'rgba(255, 255, 255, 0.77)', fontWeight:'bold'}}/>

                    <input type='text' value= 'Upload your pictures' readOnly
                     style={{width: '80%',height: '36px', borderRadius : '20px', textAlign : 'center', backgroundColor: 'rgba(255, 255, 255, 0.77)', fontWeight:'bold'}}/> */}
        </div>

        <div className='row m-3 mt-5'>
          {this.state.hideFrontScan ? (
            <div className='row text-center' style={{ marginLeft: '12px' }}>
              <div className='col '>
                <img style={{ width: '90px', height: '65px' }} src={this.state.frontImagePreview} alt='scan' />
              </div>
              <div className='col gy-3'>
                <img className='' style={{ width: '30px', height: '30px' }} src={checkCircle} alt='scan' />
              </div>
            </div>
          ) : (
            <div className='text-center'>
              <img style={{ width: '90px', height: '65px' }} src={Scan} alt='scan' />
            </div>
          )}

          <div className='row my-4 gx-0'>
            <label className='nexusButton btn btn-dark' style={this.disableText('front')}>
              <input
                type='file'
                style={{ display: 'none' }}
                onChange={(event) => this.uploadDoc(event, 'FrontSide')}
                disabled={this.state.hideFrontScan}
              />
              Scan Front
            </label>
          </div>
        </div>

        <div className='row m-3'>
          {this.state.hideBackScan ? (
            <div className='row text-center' style={{ marginLeft: '12px' }}>
              <div className='col '>
                <img style={{ width: '90px', height: '65px' }} src={this.state.backImagePreview} alt='scan' />
              </div>
              <div className='col gy-3'>
                <img className='' style={{ width: '30px', height: '30px' }} src={checkCircle} alt='scan' />
              </div>
            </div>
          ) : (
            <div className='text-center'>
              <img style={{ width: '90px', height: '65px' }} src={Scan} alt='scan' />
            </div>
          )}

          <div className='row my-4 gx-0'>
            <label className='nexusButton btn btn-dark' style={this.disableText('back')}>
              <input
                type='file'
                style={{ display: 'none' }}
                onChange={(event) => this.uploadDoc(event, 'BackSide')}
                disabled={this.state.hideBackScan}
              />
              Scan Back
            </label>
          </div>
        </div>

        {this.state.hideBackScan && this.state.hideFrontScan ? (
          <div className='row m-3' style={{ fontSize: '14px' }}>
            <button onClick={() => this.continue()} className='btn btn-dark nexusButton'>
              Continue
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    token: auth.data,
  };
};

export default connect(mapStateToProps)(uploadDocument);
