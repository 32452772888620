import React from 'react';
import { BrowserRouter } from "react-router-dom";
import MyRouter from './MainRouter';
import './style/index.scss';

const App = () => (
  <BrowserRouter>
    <MyRouter />
  </BrowserRouter>
);

export default App;