import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuoteHeader from '../../US/sharedComponents/QouteHeader/index';
import Service from '../sharedComponents/Service';
import { ISSUE_POLICY_AND_GET_CARBON_VOUCHER_AND_PAYMENT } from '../../../constants/ActionTypes';
import cardIcon from '../../../assets/icons/card.png';

class Payment extends Component {
  service = new Service();
  constructor() {
    super();
    this.state = {
      cardNumber: '',
      cardCVV: '',
      cardExpirationDate: '',
      cardHolderName: '',
      isError: false,
      errorMessage: '',
      isLoading: false,
    };
  }
  previousPage() {
    this.props.history.push('coverage-selection');
  }
  setCardHolder = (value) => {
    this.setState({
      cardHolderName: value,
    });
  };

  setCardNumber = (value) => {
    let formattedCardNumber = value.replace(/[^\d]/g, '');
    formattedCardNumber = formattedCardNumber.substring(0, 16);

    // Split the card number in groups of 4
    let cardNumberSections = formattedCardNumber.match(/\d{1,4}/g);
    if (cardNumberSections !== null) {
      formattedCardNumber = cardNumberSections.join('-');
    }

    this.setState({
      cardNumber: formattedCardNumber,
    });
  };

  setExpiryDate = (value) => {
    let formattedExpiryDate = value.replace(/[^\d]/g, '');
    formattedExpiryDate = formattedExpiryDate.substring(0, 4);

    // Split the expiry Date in groups of 2
    let expiryDateSections = formattedExpiryDate.match(/\d{1,2}/g);
    if (expiryDateSections !== null) {
      formattedExpiryDate = expiryDateSections.join('/');
    }
    this.setState({
      cardExpirationDate: formattedExpiryDate,
    });
  };

  setCvvValue = (value) => {
    this.setState({
      cardCVV: value,
    });
  };

  triggerIssuePolicyAndGetCarbonVoucher = () => {
    const { cardNumber, cardCVV, cardExpirationDate, cardHolderName } = this.state;
    const { submissionWFCoveragePersonelInfoData } = this.props;
    if (cardNumber && cardCVV && cardExpirationDate && cardHolderName) {
      this.setState({
        isError: false,
        errorMessage: '',
        isLoading: true,
      });
      let dummy = {
        consent: true,
        issuePolicyRequest: {
          accountNumber: '67677897',
          submissionId: submissionWFCoveragePersonelInfoData.submissionId,
          policyEffectiveDate: '2021-06-17T00:00:00Z',
          namedInsureds: [
            {
              namedPersonInfo: {
                address: {
                  state: 'NC',
                  country: 'US',
                  city: 'Charlotte',
                  postalCode: '23423',
                  addressLine1: 'Test Addr 1',
                },
                firstName: 'Stan',
                lastName: 'Wan',
                organizationName: 'ABC',
                emailAddress: 'stan@xyz.com',
                gender: 'MALE',
                dateOfBirth: '2017-11-21T00:00:00Z',
                phoneNumber: '3648234884',
              },
            },
          ],
          vehicles: [
            {
              annualMileage: 600,
              antiTheft: false,
              predictedAnnualCo2Emissions: '120',
              year: 2001,
              antiLockBrakes: false,
              coverages: [
                {
                  covTerms: [
                    {
                      availableOptions: [
                        {
                          amountData: {
                            amount: 0,
                            currencyType: 'USD',
                          },
                          covTermCode: 'LIMIT',
                          name: 'TestName',
                          valueAsString: 'TermVal',
                        },
                      ],
                      selectedOption: {
                        amountData: {
                          amount: 0,
                          currencyType: 'USD',
                        },
                        covTermCode: 'LIMIT',
                        name: 'Denver',
                        valueAsString: 'TermVal',
                      },
                    },
                  ],
                  coveragePatternCode: 'COL-100',
                  name: 'Collision Coverage',
                },
              ],
              costNew: null,
              overallMileage: 1000,
              vehicleLocation: {
                state: 'NC',
                country: 'US',
                city: 'Charlotte',
                postalCode: '23423',
                addressLine1: 'Test Addr 1',
              },
              engineSize: '023',
              model: 'XYZ',
              vin: '1N4AA5AP7BC819413',
              make: 'XYZ',
              co2EmissionFromDvla: '213',
            },
          ],
          drivers: [
            {
              personInfo: {
                address: {
                  state: 'NC',
                  country: 'US',
                  city: 'Charlotte',
                  postalCode: '23423',
                  addressLine1: 'Test Addr 1',
                },
                firstName: 'Stan',
                lastName: 'Wan',
                emailAddress: 'stan@xyz.com',
                gender: 'MALE',
                licenseNumber: '326823648',
                dateOfBirth: '2017-11-21T00:00:00Z',
                phoneNumber: '3648234884',
              },
              accidents: 0,
              violations: 0,
              drivingExperience: 9,
            },
          ],
          coverages: [
            {
              name: 'collision',
              covTerms: [
                {
                  availableOptions: [
                    {
                      name: 'TestName',
                      covTermCode: 'LIMIT',
                      valueAsString: 'TermVal',
                      amountData: {
                        currencyType: 'USD',
                        amount: 99,
                      },
                    },
                  ],
                  selectedOption: {
                    name: 'TestName3',
                    covTermCode: 'LIMIT',
                    valueAsString: 'TermVal3',
                    amountData: {
                      currencyType: 'USD',
                      amount: 99,
                    },
                  },
                },
              ],
              coveragePatternCode: 'collision',
            },
          ],
        },
      };

      this.service
        .issuePolicyAndGetCarbonVoucher(dummy)
        .then((res) => res.json())
        .then((data) => {
          if (data.data) {
            this.props.history.push('payment');
            this.props.dispatch({
              type: ISSUE_POLICY_AND_GET_CARBON_VOUCHER_AND_PAYMENT,
              payload: data.data,
            });
            this.props.history.push('paymentsuccess');
          } else if (data.error) {
            this.setState({
              isError: true,
              errorMessage: 'Something went wrong, please try later',
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.setState({
        isError: true,
        errorMessage: 'Please fill all the field',
        isLoading: false,
      });
    }
  };

  render() {
    const { cardNumber, cardCVV, cardExpirationDate, cardHolderName, isError, errorMessage, isLoading } = this.state;
    return (
      <div className='container px-4 py-4 payment-screen'>
        <QuoteHeader
          page={3}
          previousPage={() => {
            this.previousPage();
          }}
          display={true}
        />
        <div className='row'>
          <div className='col-12'>
            <div className='pageHeading'> PAYMENT </div>
          </div>
          <div className='col-12 h2 mt-5'>
            <div className='card-details-title'>Card Details</div>
          </div>

          <div className='col-12 mt-3'>
            {/* <div className="row card-details"> */}
            <div className='row gx-0 card p-3 fs-5' style={{ color: 'black', background: 'rgb(255 255 255 / 77%)' }}>
              <div className='col-12 h6 payment-card-titile'>Card Number</div>
              <div
                className='col-12'
                style={{
                  marginTop: '-5px',
                }}
              >
                <input
                  type='text'
                  maxLength={19}
                  placeholder='0000-0000-0000-0000'
                  style={{ width: '215px' }}
                  className='custom-card-input'
                  value={cardNumber}
                  name='cardNumber'
                  onChange={(event) => this.setCardNumber(event.target.value)}
                />
              </div>
              <div className='col-12 card-field-spacing'>
                <div className='row'>
                  <div className='col-2'>
                    <div className='row'>
                      <div className='col-12 h6 payment-card-titile'>CVC</div>
                      <div
                        className='col-12'
                        style={{
                          marginTop: '-5px',
                        }}
                      >
                        <input
                          type='text'
                          maxLength={3}
                          style={{ width: '50px' }}
                          placeholder='000'
                          className='custom-card-input'
                          value={cardCVV}
                          name='cardCVV'
                          onChange={(event) => this.setCvvValue(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-5'>
                    <div className='row'>
                      <div className='col-12 h6 payment-card-titile'>Expiration Date</div>
                      <div
                        className='col-12'
                        style={{
                          marginTop: '-5px',
                        }}
                      >
                        <input
                          style={{ width: '100px' }}
                          type='text'
                          maxLength={5}
                          className='custom-card-input'
                          placeholder='MM / YY'
                          value={cardExpirationDate}
                          name='cardExpirationDate'
                          onChange={(event) => this.setExpiryDate(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 card-field-spacing h6 payment-card-titile'>Card Holder Name</div>
              <div
                className='col-12'
                style={{
                  marginTop: '-5px',
                }}
              >
                <div className='row' style={{ position: 'relative' }}>
                  <div className='col-8'>
                    <input
                      style={{ width: '150px' }}
                      type='text'
                      placeholder='XYZ'
                      className='custom-card-input'
                      value={cardHolderName}
                      name='cardHolderName'
                      onChange={(event) => this.setCardHolder(event.target.value)}
                    />
                  </div>
                  <div className='col-4 card-style'>
                    <img src={cardIcon} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 mt-5 pt-5'>
            <button
              className='nexusButton mt-5'
              style={{ backgroundColor: '#2E2E38', color: '#fff', marginTop: '5px' }}
              onClick={() => this.triggerIssuePolicyAndGetCarbonVoucher()}
            >
              Pay
            </button>
          </div>

          {/* {isLoading ? (
            <div className="col-12 mt-5 align-item-XYcenter">
              <MotifProgressBar isIndeterminate circle />
            </div>
          ) : (
            <div className="col-12 mt-5">
              <button
                className="nexusButton"
                style={{ backgroundColor: "#2E2E38", color: "#fff" }}
                onClick={() => this.triggerIssuePolicyAndGetCarbonVoucher()}
              >
                Pay
              </button>
            </div>
          )} */}
          {/* {isError && (
            <div className="col-12 mt-2 error pageDetals"> {errorMessage}</div>
          )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { SubmissionWFCoveragePersonelInfo } = state;
  return {
    submissionWFCoveragePersonelInfoData: SubmissionWFCoveragePersonelInfo?.submissionWFCoveragePersonelInfoData,
  };
};
export default connect(mapStateToProps)(Payment);
