import {
    DRIVER_DETAILS,
} from '../constants/ActionTypes'

const initialState = {
    driverdata: null,
}


const driverdetails = (state = initialState, action) => {
    
    switch (action.type) {
        case DRIVER_DETAILS:
            return  {
                ...state,
                driverdata: action.payload
            }
        default:
            return state
    }
}

export default driverdetails;
