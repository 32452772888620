import {
    CAR_DETAILS,
} from '../constants/ActionTypes'

const initialState = {
    data: null,
}


const cardetails = (state = initialState, action) => {
    
    switch (action.type) {
        case CAR_DETAILS:
            return  {
                ...state,
                data: action.payload
            }
        default:
            return state
    }
}

export default cardetails;