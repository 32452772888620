import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Welcome from './component/Welcome';
import CarDeatils from './component/CarDeatils';
import DLScan from './component/DLScan';
import uploadDocument from './component/uploadDocument'
import DriverDetails from './component/DriverDetails';
import CarDriverDetails from './component/CarDriverDetails';
import CoveragePersonalInformation from './component/CoveragePersonalInformation';
import CoverageSelection from './component/CoverageSelection';
import Payment from './component/Payment';
import PaymentSuccess from './component/PaymentSuccess';
import Onboarding from './component/Onboarding';

const UKRouter = () => (
    <Switch>
      <Route path='/uk/welcome' component={Welcome}/>
      <Route path='/uk/cardetails' component={CarDeatils}/>
      <Route path='/uk/dlscan' component={DLScan}/>
      <Route path='/uk/uploaddoc' component={uploadDocument}/>
      <Route path='/uk/driverdetails' component={DriverDetails}/>
      <Route path='/uk/completedetails' component={CarDriverDetails}/>
      <Route path='/uk/coverage-personal-information' component={CoveragePersonalInformation}/>
      <Route path='/uk/coverage-selection' component={CoverageSelection}/>
      <Route path='/uk/payment' component={Payment}/>
      <Route path='/uk/onboarding' component={Onboarding}/>
      <Route path='/uk/paymentsuccess' component={PaymentSuccess}/>
    </Switch>
)

export default UKRouter;