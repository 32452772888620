import {
    CARBON_EMISSION,
} from '../constants/ActionTypes'

const initialState = {
    data: null,
}


const carbonemission = (state = initialState, action) => {
    
    switch (action.type) {
        case CARBON_EMISSION:
            return  {
                ...state,
                data: action.payload
            }
        default:
            return state
    }
}

export default carbonemission;