import { Component } from 'react';
import { connect } from 'react-redux';
import { NexusFormField, NexusSelect, NexusOption, NexusInput } from '@nexus/react';
import moment from 'moment';
import QuoteHeader from '../../US/sharedComponents/QouteHeader/index';
import Service from '../sharedComponents/Service';
import phoneIcon from '../../../assets/icons/phone.png';
import emailIcon from '../../../assets/icons/email.png';
import { COUNTRY_CODE } from '../../../constants/Const';
import { COVERAGE_PERSONAL_INFORMATION_SUBMISSION_WF } from '../../../constants/ActionTypes';
import Loader from '../sharedComponents/Loader';

class CoveragePersonalInformation extends Component {
  service = new Service();
  constructor() {
    super();
    this.state = {
      coverageSelected: '',
      coverageAmount: '',
      policStartDate: '',
      userEmail: '',
      countryCode: '+1',
      userPhone: '',
      isValidationSucessfull: false,
      emailError: false,
      emailErrorMessage: '',
      phoneError: false,
      phoneErrorMessage: '',
      isError: false,
      errorMessage: '',
      percentageLoadingSubmission: false,
      isValidating: false,
    };
  }
  previousPage() {
    this.props.history.push('completedetails');
  }
  doneLoading() {
    this.setState({ percentageLoadingSubmission: false });
  }
  stopLoader() {
    this.loader.loadingCompleted();
    this.setState({ percentageLoadingSubmission: false });
  }
  setPolicyStartDate = (date) => {
    this.setState({
      policStartDate: date,
    });
  };
  setUserEmail = (userEmail) => {
    this.setState({
      userEmail: userEmail,
    });
  };
  setUserPhone = (userPhone) => {
    this.setState({
      userPhone: userPhone,
    });
  };
  setCountryCode = (value) => {
    this.setState({
      countryCode: value,
    });
  };
  async letsValidate() {
    
    const { userPhone, countryCode, userEmail, policStartDate } = this.state;
    console.log('letsValidate', userPhone, countryCode, userEmail, policStartDate);
    localStorage.setItem('policStartDate', policStartDate.toString());
    if (policStartDate && userEmail && userPhone && countryCode) {
      this.setState({
        isError: false,
        errorMessage: '',
        isValidating: true,
      });
      const isPhoneValidated = await this.validatePhone();
      const isEmailValidated = await this.validateEmail();
      Promise.all([isPhoneValidated, isEmailValidated]).then((values) => {
        if (isPhoneValidated && isEmailValidated) {
          this.setState({
            isValidationSucessfull: true,
            isValidating: false,
          });
          this.submitCoverageSubmissionDetails();
        }
      });
    } else {
      this.setState({
        isError: true,
        errorMessage: 'Please enter all the details',
        isValidating: false,
      });
    }
  }
  validateEmail() {
    console.log('validateEmail');
    const { userEmail } = this.state;
    return new Promise((resolve) => {
      this.service
        .emailValidator(userEmail)
        .then((res) => res.json())
        .then((data) => {
          if (data.data) {
            resolve(true);
          } else if (data.error) {
            resolve(false);
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            emailError: true,
            emailErrorMessage: 'Something went wrong, please verify the details.',
          });
          resolve(false);
        });
    });
  }

  validatePhone() {
    console.log('validatePhone');
    const { userPhone, countryCode } = this.state;
    let phoneNumberWithCountryCode = countryCode + userPhone;
    return new Promise((resolve) => {
      this.service
        .phoneNumberValidator(phoneNumberWithCountryCode)
        .then((res) => res.json())
        .then((data) => {
          if (data.data) {
            resolve(true);
          } else if (data.error) {
            resolve(false);
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            phoneError: true,
            phoneErrorMessage: 'Something went wrong, please verify the details.',
          });
          resolve(false);
        });
    });
  }
  submitCoverageSubmissionDetails = () => {
    const { userPhone, countryCode, userEmail, policStartDate } = this.state;
    const policyDateStr = moment(policStartDate).format('YYYY-MM-DDTHH:mm:ss[Z]');

    const submissionPolicyRequestBody = {
      accountNumber: '67677897009',
      coverages: null,
      drivers: [
        {
          accidents: 0,
          delete: false,
          driverId: null,
          drivingExperience: 9,
          personInfo: {
            address: {
              addressLine1: 'Test Addr 1',
              city: 'Charlotte',
              country: 'US',
              postalCode: '23423',
              state: 'NC',
            },
            dob: '2000-11-21T00:00:00Z',
            emailAddress: userEmail,
            firstName: 'Stan',
            gender: 'MALE',
            lastName: 'Wan',
            licenseNumber: '326823648',
            mobileNumber: userPhone,
          },
          roadsDriven: {},
          violations: 0,
        },
      ],
      namedInsureds: [
        {
          namedPersonInfo: {
            address: {
              state: 'NC',
              country: 'US',
              city: 'Charlotte',
              postalCode: '23423',
              addressLine1: 'Test Addr 1',
            },
            firstName: 'David',
            lastName: 'Bowie',
            organizationName: 'Nexus',
            emailAddress: 'stan123@xyz.com',
            gender: 'MALE',
            dob: '1980-11-21T00:00:00Z',
            mobileNumber: userPhone,
          },
        },
      ],
      paymentFrequency: null,
      policyEffectiveDate: policyDateStr,
      vehicles: [
        {
          annualMileage: 600,
          antiLockBrakes: true,
          antiTheft: true,
          costNew: {},
          coverages: [
            {
              covPatternCode: 'TestCovPatter',
              covTerms: [
                {
                  availableOptions: null,
                  selectedOption: {
                    amountData: {
                      amount: 99,
                      amountType: 'Test',
                      currencyType: 'USD',
                    },
                    covTermCode: 'collision',
                    name: 'collision',
                    valueAsString: 'TermVal3',
                  },
                },
              ],
              coverageId: null,
              delete: false,
              name: 'collision',
            },
          ],
          delete: false,
          make: 'XYZ',
          model: 'XYZ',
          vehicleId: 'string',
          vehicleLocation: {
            addressLine1: 'Test Addr 1',

            city: 'Charlotte',

            country: 'US',
            postalCode: '23423',
            state: 'NC',
          },
          vin: '1N4AA5AP7BC819413',
          year: 2001,
        },
      ],
    };
    this.setState({
      percentageLoadingSubmission: true,
    });

    this.service
      .coveragePersonalInformationSubmissionWF(submissionPolicyRequestBody)
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          this.props.dispatch({
            type: COVERAGE_PERSONAL_INFORMATION_SUBMISSION_WF,
            payload: data.data,
          });
          setTimeout(() => {
            this.stopLoader();
            this.props.history.push('coverage-selection');
          }, 1000);
        } else if (data.error) {
          this.stopLoader();
        }
      })
      .catch((err) => {
        this.stopLoader();
        console.error(err);
      });
  };

  render() {
    const {
      policStartDate,
      userEmail,
      userPhone,
      phoneError,
      phoneErrorMessage,
      emailError,
      emailErrorMessage,
      isValidationSucessfull,
      countryCode,
      isError,
      errorMessage,
      percentageLoadingSubmission,
      isValidating,
    } = this.state;
    return (
      <div className='container px-4 py-4 coverage-personal-screen'>
        <QuoteHeader
          page={3}
          previousPage={() => {
            this.previousPage();
          }}
          display={true}
        />
        {!percentageLoadingSubmission && (
          <div className='row'>
            <div className='col-12 mt-4'>
              <div className='pageHeading'> ALMOST DONE </div>
            </div>
            <div className='col-12 pageDetals mt-5'>
              <div>Pick your Policy Start Date</div>
            </div>
            <div className='col-12 mt-3' style={{ fontSize: '12px', width: '235px', lineHeight: '15px' }}>
              <div className='coverage-text'>
                Your coverage will start on this date. All subsequent payments will be on this day every month.{' '}
              </div>
            </div>

            <div className='col-12 mt-4'>
              <input
                type='date'
                className='form-control'
                id='exampleFormControlInput1'
                placeholder='Date of birth'
                onChange={(event) => this.setPolicyStartDate(event.target.value)}
                name='DOB'
                value={policStartDate || new Date()}
                style={{
                  height: '44px',
                  backgroundColor: 'rgba(255, 255, 255, 0.77)',
                }}
              />
            </div>
            <div className='col-12 mt-5 h3'>Contact Details</div>
            <div className='col-12 mt-2'>
              <div className='row'>
                <div className='col-2 align-item-XYcenter'>
                  <img src={emailIcon} alt='emailIcon' style={{ width: '25px' }} />
                </div>
                <div className='col-10 mt-2' style={{ paddingLeft: '0px' }}>
                  <div className='row'>
                    <div className='col-12'>
                      <NexusFormField>
                        <NexusInput
                          className='contact-input'
                          onInput={(event) => this.setUserEmail(event.target.value)}
                          style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.77)',
                            border: 'none',
                          }}
                          value={userEmail}
                          type='text'
                          id='email'
                          aria-label='Email Field'
                          required
                          formControlName='email'
                          validationInteraction='touched'
                          pattern='^[a-zA-Z]{1}[a-zA-Z0-9.-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,}$'
                        />
                      </NexusFormField>
                    </div>
                    {/* {emailError && (
                      <div className="col-12 error pageDetals">
                        {" "}
                        {emailErrorMessage}
                      </div>
                    )}
                    {isValidationSucessfull && (
                      <div className="col-12"> Looks good</div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 mt-2'>
              <div className='row'>
                <div className='col-2 align-item-XYcenter'>
                  <img src={phoneIcon} alt='phoneIcon' style={{ width: '25px' }} />
                </div>
                <div className='col-10 mt-2' style={{ paddingLeft: '0px' }}>
                  <div className='row'>
                    <div className='col-4' style={{ width: '100px' }}>
                    <NexusFormField>
                        <NexusSelect
                         data-testid="custom-select"
                         type="custom"
                          value={countryCode}
                          name='motif-select-country-code'
                          onInput={(selectedValue) => this.setCountryCode(selectedValue)}
                        >
                          {COUNTRY_CODE.map((item) => (
                            <NexusOption key={item.key} value={item.value}>
                              {item.value}
                            </NexusOption>
                          ))}
                        </NexusSelect>
                      </NexusFormField>
                    </div>
                    <div className='col' style={{ paddingLeft: '0px' }}>
                      <NexusFormField>
                        <NexusInput
                          className='contact-input'
                          onInput={(event) => this.setUserPhone(event.target.value)}
                          value={userPhone}
                          style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.77)',
                            border: 'none',
                          }}
                          type='text'
                          id='userPhone'
                          aria-label='User Phone'
                          required
                          formControlName='userPhone'
                          validationInteraction='touched'
                        />
                      </NexusFormField>
                    </div>
                    {/* {phoneError && (
                      <div className="col-12 error pageDetals">
                        {" "}
                        {phoneErrorMessage}
                      </div>
                    )}
                    {isValidationSucessfull && (
                      <div className="col-12"> Looks good</div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 mt-5'>
              <button
                // disabled={policStartDate && userEmail && userPhone && countryCode ? false : true}
                className='btn nexusButton btn-dark'
                style={{ fontSize: '14px', height: '49px' }}
                type='submit'
                onClick={() => this.letsValidate()}
              >
                Continue
              </button>
            </div>
            {/* {isValidating ? (
              <div className="col-12 align-item-XYcenter">
                <MotifProgressBar
                  isIndeterminate
                  showLabel
                  circle
                  labelComponent={
                    <span style={{ fontSize: 14, color: "#000000" }}>
                      Validating
                    </span>
                  }
                />
              </div>
            ) : (
              <div className="col-12 mt-5">
                <button
                  disabled={policStartDate && userEmail && userPhone && countryCode ? false : true}
                  className="btn nexusButton btn-dark"
                  style={{ fontSize: "14px" }}
                  type="submit"
                  onClick={() => this.letsValidate()}
                >
                  Continue
                </button>
              </div>
            )} */}
            {/* {isError && (
              <div className="col-12 mt-2 error pageDetals">
                {" "}
                {errorMessage}
              </div>
            )} */}
          </div>
        )}
        {percentageLoadingSubmission && (
          <div className='row'>
            <div className='col-xs-12'>
              <p className='pageHeading'> COVERAGE </p>
            </div>

            <div className='col-xs-12 center'>
              <Loader
                ref={(instance) => {
                  this.loader = instance;
                }}
                doneLoading={() => this.doneLoading()}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect()(CoveragePersonalInformation);
