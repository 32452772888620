import { Component } from "react";
import { connect } from "react-redux";
import UKFlag from "../assets/images/UK Flag.png";
import USFlag from "../assets/images/US Flag.png";
import { eyLogo } from '../assets/icons';
import '../style/Landing.css';

class LandingPage extends Component {
  constructor() {
    super();
    this.state = {};
  }

  USModule() {
    this.props.history.push(`/us/welcome`);
  }

  UKModule() {
    this.props.history.push(`/uk/welcome`);
  }

  render() {
    return (
      <div className="container landing-page">
        <div className='landing-page-gradient'>
          <div className="landing-header m-5">
            <img src={eyLogo} className="landing-header-logo" alt="logo"></img>
            <div className='landing-header-text'>Nexus</div>
          </div>

          <div className="my-4" style={{fontSize: '18px', lineHeight: '20px',}}>
            <p className="landingPageDetals">
              The Easiest way to protect yourself and your vehicle.{" "}
            </p>
          </div>

          <div className="mt-5 text-center">
            <img style={{ cursor: 'pointer' }} src={USFlag} alt="USFlag" onClick={() => this.USModule()} />
          </div>

          <div className="mt-5 text-center">
            <img style={{ cursor: 'pointer' }} src={UKFlag} alt="UKFlag" onClick={() => this.UKModule()} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(LandingPage);
