export const ADD_ADDRESS = 'ADD_ADDRESS';
export const ADD_POLICY= 'ADD_POLICY';
export const GET_PARTY_SUCCESS = 'GET_PARTY_SUCCESS';
export const ADD_COVERAGE = 'ADD_COVERAGE';
export const UPDATE_PARTY = 'UPDATE_PARTY';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const ADD_FORMATED_ADDRESS = 'ADD_FORMATED_ADDRESS';

/* Globally used */

export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';

/* UK flow */

export const CAR_DETAILS = 'CAR_DETAILS';
export const USER_CAR_PREFERENCE = 'USER_CAR_PREFERENCE';
export const CARBON_EMISSION = 'CARBON_EMISSION';
export const DRIVER_DETAILS = 'DRIVER_DETAILS';
export const COVERAGE_PERSONAL_INFORMATION_SUBMISSION_WF = 'COVERAGE_PERSONAL_INFORMATION_SUBMISSION_WF';
export const ISSUE_POLICY_AND_GET_CARBON_VOUCHER_AND_PAYMENT = 'ISSUE_POLICY_AND_GET_CARBON_VOUCHER_AND_PAYMENT';



/* US flow */