import {
    GET_TOKEN_SUCCESS,
} from '../constants/ActionTypes'

const initialState = {
    data: null,
}


const address = (state = initialState, action) => {
    switch (action.type) {
        case GET_TOKEN_SUCCESS:
            return  {
                ...state,
                data: action.payload
            }
        default:
            return state
    }
}

export default address;